import { Button, Descriptions, Spin } from "antd";
import { has, isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { StoreState } from "../../store";
import { mijnActions } from "../../store/mijn";

interface LidmaatschapProps {
    basePath: string;
}

export const Lidmaatschap = (props: LidmaatschapProps) => {
    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(mijnActions.getContactGegevens());
            dispatch(mijnActions.getLidmaatschap());
        },
        [false]
    );

    const { member, subscription } = useSelector((state: StoreState) => ({
        member: state.mijn.member,
        subscription: state.mijn.subscription
    }));

    const [loading, setLoading] = useState(true);
    const [loadingMember, setLoadingMember] = useState(false);
    const [loadingSubscription, setLoadingSubscription] = useState(false);

    useEffect(
        () => {
            setLoadingSubscription(loadingSubscription ? false : true);
        },
        [subscription]
    );

    useEffect(
        () => {
            setLoadingMember(loadingMember ? false : true);
        },
        [member]
    );

    useEffect(
        () => {
            if (!loadingMember && !loadingSubscription) {
                setLoading(false);
            } else {
                setLoading(true);
            }
        },
        [loadingMember, loadingSubscription]
    );

    const content: React.ReactNode[] = [];
    const actions: React.ReactNode[] = [];

    if (loading) {
        content.push(<Spin key="content" />);
    } else if (!member || isEmpty(member)) {
        content.push(<p key="content">Om lid te worden hebben we eerst uw contactgegevens nodig.</p>);
        actions.push(
            <NavLink key="contact" to={props.basePath + "/contact"}>
                <Button type="primary">Contactgegevens invullen</Button>
            </NavLink>
        );
    } else if (!subscription || !has(subscription, "lidnummer")) {
        content.push(<p key="content">U bent nog geen lid van Steunpunt Levend Erfgoed vzw</p>);
        actions.push(
            <NavLink key="lid-worden" to={props.basePath + "/lidgeld-betalen"}>
                <Button type="primary">Lid worden van SLE</Button>
            </NavLink>
        );
    } else {
        content.push(
            <Descriptions key="content">
                <Descriptions.Item label="Lidnummer">{subscription.lidnummer}</Descriptions.Item>
                <Descriptions.Item label="Type">{subscription.type}</Descriptions.Item>
                <Descriptions.Item label="Status">{subscription.status}</Descriptions.Item>
                <Descriptions.Item label="Einde">{subscription.einde}</Descriptions.Item>
            </Descriptions>
        );

        if (subscription.status === "te-vernieuwen") {
            actions.push(
                <NavLink key="lid-worden" to={props.basePath + "/lidgeld-betalen"}>
                    <Button type="primary">Mijn lidmaatschap vernieuwen</Button>
                </NavLink>
            );
        }
    }

    return (
        <Fragment>
            <h2>Mijn lidmaatschap</h2>
            {content}
            {actions}
        </Fragment>
    );
}
