
import { Layout, Menu } from "antd";
import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import { List } from "./List";

export const Qr = (props: { basePath: string }) => {
	return (
		<Layout>
			<Layout.Sider width={200} style={{ background: "#fff" }}>
				<Menu>
					<Menu.Item key="lijst"><NavLink to={props.basePath}>Lijst codes</NavLink></Menu.Item>
				</Menu>
			</Layout.Sider>
			<Layout style={{ padding: "24px 24px 0 24px" }}>
				<Layout.Content style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}>
					<Switch>
						<Route key="lijst" path={props.basePath} exact component={List} />
					</Switch>
				</Layout.Content>
			</Layout>
		</Layout>
	);
};
