import { extend } from "lodash";
import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { StoreState } from ".";
import { loadLanden, loadTitels } from "../api/data";

export interface DataState {
    landen: string[];
    titels: string[];
}

export const dataActions = {
    loadLanden: (): AnyAction => {
        return { type: "LOAD_LANDEN" };
    },
    loadTitels: (): AnyAction => {
        return { type: "LOAD_TITELS" };
    }
};

const initialState: DataState = {
    landen: [],
    titels: []
};

export function dataReducer(state: DataState = initialState, action: AnyAction): DataState {
    if (!action) {
        return extend({}, state);
    }

    switch (action.type) {
        case "LANDEN_LOADED":
            return extend({}, state, { landen: action.payload });
        case "TITELS_LOADED":
            return extend({}, state, { titels: action.payload });
        default:
            return extend({}, state);
    }
}

export function* dataSaga() {
    yield takeLatest("LOAD_LANDEN", loadLandenSaga);
    yield takeLatest("LOAD_TITELS", loadTitelsSaga);
}

function* loadLandenSaga() {
    const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
    const landen = yield call(loadLanden, accessToken);
    yield put({ type: "LANDEN_LOADED", payload: landen });
}

function* loadTitelsSaga() {
    const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
    const titels = yield call(loadTitels, accessToken);
    yield put({ type: "TITELS_LOADED", payload: titels });
}
