import { AutoComplete, Button, Checkbox, Collapse, Form, InputNumber, notification, Radio, Select, Table } from "antd";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { RadioChangeEvent } from "antd/lib/radio";
import { extend, filter, isEmpty, map, pick, forEach } from "lodash";
import React, { FormEvent, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { StoreState } from "../../../store";
import { mijnActions } from "../../../store/mijn";
import { getAsyncUiState } from "../../../store/ui";
import { Ras, vereenvoudigdBeheerActions } from "../../../store/vereenvoudigdBeheer";
import { InvoerData } from "./interfaces";

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 2 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 10 }
	}
};

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 10,
			offset: 2
		}
	}
};

function getColumnRenderer(name: string) {
	return (text: any, record: any) => {
		return (
			<Form.Item name={name + "-" + record.id.toString()}>
				<InputNumber min={0} step={1} />
			</Form.Item>
		);
	};
}

function getColumns(typeAantal: string) {

	const result: { title: string; dataIndex?: string; key: string; render?: any; }[] = [{
		title: "Kleur",
		dataIndex: "kleur",
		key: "kleur"
	}];

	if (typeAantal === "globaal") {
		result.push({ title: "Globaal aantal dieren", key: "globaal", render: getColumnRenderer("aantalGlobaal") });
	}

	if (typeAantal === "jong-oud") {
		result.push({ title: "Aantal jonge dieren", key: "jong", render: getColumnRenderer("aantalJong") });
		result.push({ title: "Aantal volwassen dieren", key: "oud", render: getColumnRenderer("aantalVolwassen") });
	}

	if (typeAantal === "man-vrouw") {
		result.push({ title: "Aantal vrouwelijke dieren", key: "vrouw", render: getColumnRenderer("aantalVrouw") });
		result.push({ title: "Aantal mannelijke dieren", key: "man", render: getColumnRenderer("aantalMan") });
	}

	if (typeAantal === "detail") {
		result.push({ title: "Aantal jonge vrouwelijke dieren", key: "jong-vrouw", render: getColumnRenderer("aantalVrouwJong") });
		result.push({ title: "Aantal volwassen vrouwelijke dieren", key: "oud-vrouw", render: getColumnRenderer("aantalVrouwVolwassen") });
		result.push({ title: "Aantal jonge mannelijke dieren", key: "jong-man", render: getColumnRenderer("aantalManJong") });
		result.push({ title: "Aantal volwassen mannelijke dieren", key: "oud-man", render: getColumnRenderer("aantalManVolwassen") });
	}

	return result;
}

function getDataSource(kleuren: { id: number, kleur: string }[], typeAantal: string) {
	if (isEmpty(kleuren)) {
		kleuren = [{ id: 0, kleur: "Alle kleuren" }]
	}

	return map(kleuren, kleur => ({
		id: kleur.id,
		kleur: kleur.kleur
	}));
}

export const Invoer = () => {

	const dispatch = useDispatch();

	const { rassen, kleuren } = useSelector((state: StoreState) => ({
		rassen: state.vereenvoudigdBeheer.rassen,
		kleuren: state.vereenvoudigdBeheer.kleuren
	}));

	const [form] = Form.useForm();

	const [checked, setChecked] = useState(false);
	const [selectedRas, setSelectedRas] = useState<number | undefined>(undefined);
	const [selectedKleuren, setSelectedKleuren] = useState<any[]>([]);
	const [typeTelling, setTypeTelling] = useState<string | undefined>(undefined);
	const [typeAantal, setTypeAantal] = useState<string | undefined>(undefined);

	const [asyncVersturenState, resetAsyncVersturenState] = getAsyncUiState(mijnActions.saveVereenvoudigdBeheerInvoer.toString());

	useEffect(
		() => {
			dispatch(vereenvoudigdBeheerActions.loadRassen());
			dispatch(vereenvoudigdBeheerActions.loadKleuren());
		},
		[false]
	);

	useEffect(
		() => {
			if (asyncVersturenState.hasCompleted) {
				if (asyncVersturenState.error) {
					notification.error({ message: "Oeps...", description: "Er is iets fout gegaan, probeer nog eens of neem contact op met SLE. (" + asyncVersturenState.error.toString() + ")" });
				} else {
					notification.success({ message: "Geslaagd", description: "We hebben uw gegevens goed ontvangen" });
					setChecked(false);
					setSelectedRas(undefined);
					setSelectedKleuren([]);
					setTypeAantal(undefined);
					setTypeTelling(undefined);
					form.resetFields();
				}

				resetAsyncVersturenState();
			}
		},
		[asyncVersturenState.isRunning]
	)

	function onChangeRas(v: number) {
		setSelectedRas(isEmpty(v) ? undefined : v);
		setChecked(false);
		form.setFieldsValue({ kleur: [] });
	}

	function onChangeKleur(v: any, options: any) {
		setSelectedKleuren(map(options, opt => ({ id: parseInt(opt.value), kleur: opt.children })));
		setChecked(false);
	}

	function onChangeTypeTelling(e: RadioChangeEvent) {
		setTypeTelling(e.target.value);
		setChecked(false);
	}

	function onChangeTypeAantal(e: RadioChangeEvent) {
		setTypeAantal(e.target.value);
		setChecked(false);
	}

	function onFinish(values: any) {
		const kleuren = values.kleur || [];
		if (isEmpty(kleuren)) {
			kleuren.push("0");
		}

		const data: InvoerData[] = [];

		forEach(kleuren, kleur => {
			const kleurId = parseInt(kleur);

			const invoer: InvoerData = {
				rasId: parseInt(values.ras),
				kleurId: kleurId < 1 ? undefined : kleurId,
				typeTelling: values.typeTelling,
				typeAantal: values.typeAantal
			};

			if (typeAantal === "globaal") {
				invoer.aantalGlobaal = values["aantalGlobaal-" + kleur] || 0;
				invoer.aantalVolwassen = undefined;
				invoer.aantalJong = undefined;
				invoer.aantalVrouw = undefined;
				invoer.aantalMan = undefined;
				invoer.aantalManJong = undefined;
				invoer.aantalManVolwassen = undefined;
				invoer.aantalVrouwJong = undefined;
				invoer.aantalVrouwVolwassen = undefined;
			}

			if (typeAantal === "jong-oud") {
				invoer.aantalGlobaal = undefined;
				invoer.aantalVolwassen = values["aantalVolwassen-" + kleur] || 0;
				invoer.aantalJong = values["aantalJong-" + kleur] || 0;
				invoer.aantalVrouw = undefined;
				invoer.aantalMan = undefined;
				invoer.aantalManJong = undefined;
				invoer.aantalManVolwassen = undefined;
				invoer.aantalVrouwJong = undefined;
				invoer.aantalVrouwVolwassen = undefined;
			}

			if (typeAantal === "man-vrouw") {
				invoer.aantalGlobaal = undefined;
				invoer.aantalVolwassen = undefined;
				invoer.aantalJong = undefined;
				invoer.aantalVrouw = values["aantalVrouw-" + kleur] || 0;
				invoer.aantalMan = values["aantalMan-" + kleur] || 0;
				invoer.aantalManJong = undefined;
				invoer.aantalManVolwassen = undefined;
				invoer.aantalVrouwJong = undefined;
				invoer.aantalVrouwVolwassen = undefined;
			}

			if (typeAantal === "detail") {
				invoer.aantalGlobaal = undefined;
				invoer.aantalVolwassen = undefined;
				invoer.aantalJong = undefined;
				invoer.aantalVrouw = undefined;
				invoer.aantalMan = undefined;
				invoer.aantalManJong = values["aantalManJong-" + kleur] || 0;
				invoer.aantalManVolwassen = values["aantalManVolwassen-" + kleur] || 0;
				invoer.aantalVrouwJong = values["aantalVrouwJong-" + kleur] || 0;
				invoer.aantalVrouwVolwassen = values["aantalVrouwVolwassen-" + kleur] || 0;
			}

			data.push(invoer);
		});

		dispatch(mijnActions.saveVereenvoudigdBeheerInvoer(data));
	}

	return (
		<Collapse>
			<Collapse.Panel header="Invoer gegevens" key="invoer">
				<Form {...formItemLayout} form={form} initialValues={{}} onFinish={onFinish}>
					<Form.Item label="Ras" name="ras" rules={[{ required: true }]}>
						<Select showSearch placeholder="Kies het ras (typ enkele karakters van de naam)" optionFilterProp="children" onChange={onChangeRas}
							filterOption={(input, option) =>
								option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							disabled={asyncVersturenState.isRunning}>
							{map(filter(rassen, ras => ras.actief), ras => (<Select.Option key={ras.id} value={ras.id}>{ras.naam}</Select.Option>))}
						</Select>
					</Form.Item>
					{!!selectedRas && (
						<Fragment>
							<Form.Item label="Kleur" name="kleur" extra="U kan dit veld leeg laten om geen onderscheid te maken tussen de verschillende kleuren">
								<Select mode="multiple" placeholder="Kies de kleuren" disabled={asyncVersturenState.isRunning} onChange={onChangeKleur}>
									{map(kleuren[selectedRas], kleur => (<Select.Option key={kleur.id} value={kleur.id.toString()}>{kleur.kleur}</Select.Option>))}
								</Select>
							</Form.Item>
							<Form.Item label="Telling" name="typeTelling">
								<Radio.Group disabled={asyncVersturenState.isRunning} onChange={onChangeTypeTelling}>
									<Radio value="exact">Ik heb een vrij correct idee van het aantal dieren</Radio>
									<Radio value="schatting">Ik heb mijn dieren niet geteld maar geef een schatting van het aantal</Radio>
								</Radio.Group>
							</Form.Item>
						</Fragment>
					)}
					{!!typeTelling && (
						<Form.Item label="Aantal" name="typeAantal">
							<Radio.Group disabled={asyncVersturenState.isRunning} onChange={onChangeTypeAantal}>
								<Radio value="detail">Ik heb een idee van het aantal jonge en volwassen dieren per geslacht</Radio>
								<Radio value="man-vrouw">Ik heb alleen een idee van het aantal dieren per geslacht</Radio>
								<Radio value="jong-oud">Ik heb alleen een idee van het aantal jonge en volwassen dieren</Radio>
								<Radio value="globaal">Ik moet me beperken tot een globaal aantal dieren</Radio>
							</Radio.Group>
						</Form.Item>
					)}
					{!!typeAantal && (
						<Form.Item label="Aantal dieren" wrapperCol={{ span: 22 }}>
							<Table columns={getColumns(typeAantal)} dataSource={getDataSource(selectedKleuren, typeAantal)} pagination={false} />
						</Form.Item>
					)}
					<Form.Item {...tailFormItemLayout}>
						<Checkbox disabled={asyncVersturenState.isRunning || !typeAantal} checked={checked} onChange={() => setChecked(!checked)}>Ik bevestig dat de gegevens correct zijn</Checkbox>
						<br />
						{!asyncVersturenState.isRunning && (
							<Button type="primary" htmlType="submit" disabled={!checked}>Versturen</Button>
						)}
						{asyncVersturenState.isRunning && (
							<Button type="primary" htmlType="submit" loading={true}>Versturen</Button>
						)}
					</Form.Item>
				</Form>
			</Collapse.Panel>
		</Collapse>
	);
};
