import { Form } from "@ant-design/compatible";
import { Alert, AutoComplete, Button, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { extend, filter, isEmpty, map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { StoreState } from '../../store';
import { memberActions } from '../../store/member';
import { websiteActions } from '../../store/website';


const FormItem = Form.Item;

interface LinkWebUserComponentProps extends FormComponentProps {
    mid: number;

    webusers: Array<any>;

    loadWebUsers: () => void;
    linkWebUser: (mid: number, uid: string) => Promise<AnyAction>;
    reloadWebUser: (mid: number) => void;
}

interface LinkWebUserComponentState {
    status: 'none' | 'saving' | 'saved' | 'error';
    webUsersDataSource: Array<{ text: string, value: string }>;
    webUserSelected: boolean;
}

class LinkWebUserComponent extends React.Component<LinkWebUserComponentProps, LinkWebUserComponentState> {

    constructor(props: LinkWebUserComponentProps) {
        super(props);
        this.state = {
            status: 'none',
            webUsersDataSource: [],
            webUserSelected: false
        }
    }

    componentDidMount() {
        this.props.loadWebUsers();
    }

    onLink(e: React.FormEvent<any>) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                this.setState({ status: 'saving' });
                this.props.linkWebUser(this.props.mid, values.webuser)
                    .then(() => {
                        this.props.reloadWebUser(this.props.mid);
                    })
                    .catch(() => {
                        this.setState({ status: 'error' });
                    });
            }
        });
    }

    search(value: string) {
        let data: Array<{ text: string, value: string }> = [];
        if (value) {
            data = map(filter(this.props.webusers, webuser => {
                return (webuser.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
            }), webuser => { return { text: webuser.name + " (" + webuser.mail + ")", value: webuser.uid }; })
        }

        this.setState({
            webUsersDataSource: data,
            webUserSelected: false
        });
    }

    select(value: any) {
        this.setState({
            webUserSelected: !isEmpty(value)
        });
    }

    render() {

        if (isEmpty(this.props.webusers)) {
            return (
                <div>
                    <p>Er is aan deze gegevens nog geen web-gebruiker gekoppeld. Kies de webgebruiker en klik op koppelen.</p>
                    <Spin />
                </div>
            );
        }

        let alert: React.ReactNode = null;
        switch (this.state.status) {
            case 'saving':
                alert = (<Alert message="De gegevens worden opgeslaan" type="info" />);
                break;
            case "error":
                alert = (<Alert message="Er is een fout opgetreden bij het opslaan van de gegevens" type="error" />);
                break;
        }

        const getFieldDecorator = this.props.form.getFieldDecorator;

        return (
            <div>
                {alert}
                <p>Er is aan deze gegevens nog geen web-gebruiker gekoppeld. Kies de webgebruiker en klik op koppelen.</p>
                <Form layout="inline" onSubmit={(e) => this.onLink(e)}>
                    <FormItem label="Gebruikersnaam">
                        {getFieldDecorator(
                            'webuser',
                            {
                                rules: [{ required: true, message: 'Gelieve een webgebruiker te selecteren' }],
                            }
                        )(
                            <AutoComplete dataSource={this.state.webUsersDataSource} onSearch={(v) => { this.search(v); }} onSelect={(v) => { this.select(v); }} style={{ width: "400px" }} />
                        )}
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" disabled={!this.state.webUserSelected}>Koppelen</Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState, ownProps: any) => {
    return extend(
        {},
        ownProps,
        {
            webusers: state.website.webusers
        }
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadWebUsers: () => {
            dispatch(websiteActions.loadWebUsers());
        },
        linkWebUser: (mid: number, uid: string): Promise<AnyAction> => {
            return dispatch(memberActions.linkWebUser(mid, uid));
        },
        reloadWebUser: (mid: number): void => {
            dispatch(memberActions.getWebUser(mid));
        }
    }
}

export const LinkWebUser = connect(mapStateToProps, mapDispatchToProps)(Form.create()(LinkWebUserComponent));