
import { Button, Checkbox, notification } from "antd";
import React from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { StoreState } from "../../../store";
import { mijnActions } from "../../../store/mijn";

interface IntroState {
    agree: boolean;
}

interface IntroProps {
    deelnemenAanVereenvoudigdBeheer: () => Promise<void>;
}

class IntroComponent extends React.Component<IntroProps, IntroState> {

    constructor(props: any) {
        super(props);

        this.state = {
            agree: false
        };
    }

    toggleAgree() {
        this.setState({
            agree: !this.state.agree
        });
    }

    async deelnemen() {
        try {
            await this.props.deelnemenAanVereenvoudigdBeheer();
        } catch (err) {
            notification.error({ message: "Oeps...", description: "Er is iets fout gelopen, probeer het nog een keer of neem contact op met SLE. (" + err.message + ")" });
        }
    }

    render() {
        return (
            <div>
                <p>
                    Steunpunt Levend Erfgoed is een vereniging van vrijwilligers die zich als doel stelt de fokkers en
                    eigenaars van ons Levend Erfgoed te ondersteunen en onze rassen te promoten. Enkel met dit doel
                    voor ogen worden verzamelde gegevens gebruikt. We willen een open en transparant (privacy)beleid
                    voeren dat enkel ten dienste staat van de geregistreerde personen. Er is steeds de mogelijkheid
                    uw gegevens op te vragen en aan te passen (via info at sle.be). Met andere woorden u hebt alle
                    controle over uw eigen gegevens op elk moment.
                    </p>
                <p>
                    Door u te registreren gaat u ermee akkoord dat SLE uw gegevens mag gebruiken voor het opstellen
                    van anonieme statistieken en overzichten. Uw gegevens worden niet doorgegeven aan derden zonder
                    uw uitdrukkelijke toestemming.
                </p>
                <p>
                    <Checkbox checked={this.state.agree} onClick={() => this.toggleAgree()}>Ik ga akkoord en wens deel 
                    te nemen aan het vereenvoudigd beheer.</Checkbox>
                </p>
                <p>
                    <Button disabled={!this.state.agree} onClick={() => this.deelnemen()} type="primary">Deelnemen</Button>
                </p>
            </div>
        );
    }
}

function mapStateToProps(state: StoreState, ownProps: any): any {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch): any {
    return {
        deelnemenAanVereenvoudigdBeheer: (): Promise<AnyAction> => {
            return dispatch(mijnActions.deelnemenVereenvoudigdBeheer());
        }
    };
}

export const Intro = connect(mapStateToProps, mapDispatchToProps)(IntroComponent);
