import { Button, Form, Input, notification, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { map } from "lodash";
import moment from "moment";
import { OptionsType } from "rc-select/lib/interface";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { memberActions } from "../../store/member";
import { getAsyncUiState } from "../../store/ui";
import { SubscriptionStatusBadge } from "./SubscriptionStatusBadge";

export const SubscriptionPayments: React.FC = () => {
	const dispatch = useDispatch();
	const [form] = useForm();
	const [spIndex, setSpIndex] = React.useState<number>(0);
	const [sp, setSp] = React.useState<any | null>(null);
	const [asyncPaymentState, resetAsyncPaymentState] = getAsyncUiState(memberActions.registerSubscriptionPayment.toString());

	const { subscriptionPayments, members } = useSelector((store: StoreState) => ({
		subscriptionPayments: store.member.subscriptionPayments,
		members: store.member.members
	}));

	React.useEffect(
		() => {
			dispatch(memberActions.getSubscriptionPayments());
			dispatch(memberActions.findAll());
		},
		[false]
	);

	React.useEffect(
		() => {
			const _sp = subscriptionPayments && subscriptionPayments.length > 0 ? subscriptionPayments[spIndex] : null
			setSp(_sp);
			if (_sp) {
				form.setFieldsValue({'pid': _sp.id, 'bedrag': _sp.bedrag});
			}
		},
		[subscriptionPayments, spIndex]
	);

	React.useEffect(
		() => {
			if (asyncPaymentState.isRunning) {
				notification.info({ key: "payment", message: "Even geduld", description: "De betaling wordt verwerkt" });
			}

			if (asyncPaymentState.hasCompleted) {
				if (asyncPaymentState.error) {
					notification.error({ key: "payment", message: "Oeps...", description: "Fout: " + asyncPaymentState.error.toString() });
				} else {
					notification.success({ key: "payment", message: "Ok", description: "De betaling werd verwerkt" });
					setSpIndex(spIndex + 1);
					form.resetFields();
				}

				resetAsyncPaymentState();
			}
		},
		[asyncPaymentState]
	)

	function onFinish(values: any) {
		dispatch(memberActions.registerSubscriptionPayment({ mid: values.mid, values: { type: 'betalend', bedrag: values.bedrag, pid: values.pid } }));
	}

	if (!sp) {
		return (
			<Spin />
		)
	}

	function renderSelectOption(member: any) {
		return (
			<><SubscriptionStatusBadge status={member.status} text={member.lidnummer} /> - {member.einde} - {member.naam} {member.voornaam} {member.organisatie}</>
		);
	}

	const selectOptions: OptionsType = map(members, m => ({ key: m.lidnummer + "-" + m.naam + "-" + m.organisatie, label: renderSelectOption(m), value: m.id }));

	function filterOption(input: any, option: any) {
		return (option.key || "").toLowerCase().includes(input.toLowerCase());
	}

	return (
		<div>
			<h2>Betalingen lidgeld</h2>
			<Form form={form} layout="vertical" onFinish={onFinish}>
				<p>{moment(sp.datum).format("DD/MM/YYYY")} - {sp.bedrag} &euro;</p>
				<p>{sp.notitie}</p>
				<Form.Item hidden name="pid" initialValue={sp.id}>
					<Input />
				</Form.Item>
				<Form.Item hidden name="bedrag" initialValue={sp.bedrag}>
					<Input />
				</Form.Item>
				<Form.Item name="mid">
					<Select showSearch filterOption={filterOption} options={selectOptions} disabled={sp.status != 'nieuw'}></Select>
				</Form.Item>
				<Button type="primary" htmlType="submit">Opslaan</Button>
			</Form>
		</div>
	)
}
