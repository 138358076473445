
import axios from "axios";
import { config } from "../config";
import { QrCode } from "../store/qr";
import { downloadFile, Report } from "./reports";

export async function getQrCodes(accessToken: string): Promise<QrCode[]> {
    const response = await axios.get(config.apiUri + "/qr", {
        headers: { authorization: "Bearer " + accessToken }
    });

    const qrCodes = response.data || [];
    qrCodes.forEach((qrCode: QrCode) => {
        qrCode.enabled = qrCode.enabled ? true : false;
    });

    return qrCodes;
}

export async function createQrCode(accessToken: string, code: { name: string, link: string, enabled: boolean }): Promise<void> {
    const response = await axios.post(
        config.apiUri + "/qr",
        code,
        {
            headers: { authorization: "Bearer " + accessToken }
        }
    );

    return response.data;
}

export async function updateQrCode(accessToken: string, code: { id: number, name: string, link: string, enabled: boolean }): Promise<void> {
    const response = await axios.patch(
        config.apiUri + "/qr/" + code.id,
        { name: code.name, link: code.link, enabled: code.enabled },
        { headers: { authorization: "Bearer " + accessToken } }
    );

    return response.data;
}

export async function deleteQrCode(accessToken: string, id: number): Promise<void> {
    const response = await axios.delete(
        config.apiUri + "/qr/" + id,
        { headers: { authorization: "Bearer " + accessToken } }
    );

    return response.data;
}

const imageTypeToMime: { [type: string]: string } = {
    eps: "application/postscript",
    png: "image/png"
};

export async function downloadQrCodeImage(accessToken: string, type: string, code?: QrCode): Promise<Report> {
    if (!code) {
        throw new Error("Not supported yet");
    }

    const response = await axios.get(
        config.apiUri + "/qr/image/" + code.id,
        {
            headers: {
                authorization: "Bearer " + accessToken,
                accept: imageTypeToMime[type]
            }
        }
    );

    return downloadFile(response.data.uri, code.name + "." + type);
}
