import { extend, includes, some, values } from "lodash";
import { AnyAction } from "redux";
import { Action as OidcAction, SESSION_TERMINATED, USER_EXPIRED, USER_FOUND, USER_SIGNED_OUT } from "redux-oidc";
import { call, put, takeLatest } from "redux-saga/effects";
import { getUserData } from "../api/userData";

export interface UserDataState {
    profile: UserProfile;
}

export interface UserProfile {
    uid: string;
    email: string;
    name: string;
    roles: string[];
    hasAnyRole: (roles: string[]) => boolean;
}

const emptyProfile: UserProfile = {
    uid: "",
    email: "",
    name: "",
    roles: [],
    hasAnyRole: (roles: string[]) => false
};

export function userDataReducer(state: UserDataState = { profile: emptyProfile }, action: AnyAction): UserDataState {
    switch (action.type) {
        case "USER_DATA_LOAD_SUCCESS":
            return extend({}, state, { profile: new Profile(action.payload) });
        case "USER_DATA_LOAD_FAILURE":
        case USER_EXPIRED:
        case USER_SIGNED_OUT:
        case SESSION_TERMINATED:
            return extend({}, state, { profile: emptyProfile });
    }

    return extend({}, state);
}

export function* userDataSaga() {
    yield takeLatest(USER_FOUND, userFoundSaga);
}

function* userFoundSaga(action: OidcAction<any>): any {
    const { payload, error } = action;
    if (payload) {
        const userData = yield call(getUserData, payload.access_token);
        yield put({ type: "USER_DATA_LOAD_SUCCESS", payload: userData });
    } else if (error) {
        yield put({ type: "USER_DATA_LOAD_FAILURE", error: error });
    }
}

class Profile implements UserProfile {
    uid: string;
    email: string;
    name: string;
    roles: string[];

    constructor(input: any) {
        this.uid = input.uid;
        this.email = input.email;
        this.name = input.name;
        this.roles = values(input.roles);
    }

    hasAnyRole(roles: string[]): boolean {
        return some(roles, role => {
            return includes(this.roles, role);
        });
    }
}
