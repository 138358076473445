
import { Badge } from "antd";
import React, { ReactElement } from "react";

const mapSubscriptionStatus: { [status: string]: "success" | "processing" | "default" | "error" | "warning" } = {
	"actief": "success",
	"inactief": "default",
	"te-vernieuwen": "warning"
};

export const SubscriptionStatusBadge: React.FC<{ status: string, title?: string, text?: string; }> = (props): ReactElement<any, any> => {
	return (
		<Badge status={mapSubscriptionStatus[props.status] || "default"} title={props.title} text={props.text} />
	);
};
