import { Button, Col, Form, Input, notification, Radio, Row, Select, Spin } from "antd";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { dataActions } from "../../store/data";
import { mijnActions } from "../../store/mijn";
import { getAsyncUiState } from "../../store/ui";

export const Gegevens = () => {

	const dispatch = useDispatch();
	const [form] = Form.useForm();

	useEffect(
		() => {
			dispatch(mijnActions.getContactGegevens());
			dispatch(dataActions.loadLanden());
			dispatch(dataActions.loadTitels());
		},
		[false]
	);

	const { member, landen, titels } = useSelector((state: StoreState) => ({
		member: state.mijn.member || {},
		landen: state.data.landen || [],
		titels: state.data.titels || []
	}));

	if (!member) {
		return (
			<Spin />
		);
	}

	const [asyncUpdateState, resetAsyncUpdateState] = getAsyncUiState(mijnActions.updateContactGegevens.toString());

	useEffect(
		() => {
			if (asyncUpdateState.isRunning) {
				notification.info({
					key: "update-contact", message: "Even geduld", description: "We verwerken uw gegevens"
				});
			}

			if (asyncUpdateState.hasCompleted) {
				if (asyncUpdateState.error) {
					notification.error({
						key: "update-contact", message: "Oeps...", description: "Er is een fout opgetreden, probeer nog eens of contacteer SLE (info@sle.be). " + asyncUpdateState.error.toString()
					});
				} else {
					notification.success({
						key: "update-contact", message: "Ok", description: "We hebben uw gegevens opgeslaan"
					});
				}

				resetAsyncUpdateState();
			}
		},
		[asyncUpdateState.isRunning]
	);

	useEffect(
		() => {
			console.log(member);
			form.resetFields();
		},
		[member, landen, titels]
	);

	function formFinish(values: any) {
		dispatch(mijnActions.updateContactGegevens(values));
	}

	return (
		<Fragment>
			<h2>Mijn contactgegevens</h2>
			<Form form={form} layout="vertical" initialValues={member} onFinish={formFinish}>
				<Row gutter={16}>
					<Col span={4}>
						<Form.Item label="Titel" name="titel" rules={[{ required: true, message: "Gelieve uw titel te kiezen" }]}>
							<Select>
								{titels.map(titel => <Select.Option key={titel} value={titel}>{titel}</Select.Option>)}
							</Select>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Voornaam" name="voornaam" rules={[{ required: true, message: "Gelieve uw voornam in te vullen", whitespace: true, max: 64 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Naam" name="naam" rules={[{ required: true, message: "Gelieve uw naam in te vullen", whitespace: true, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Organisatie" name="organisatie" rules={[{ required: false, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="BTW-nummer" name="btwnummer" rules={[{ required: false, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item label="Adres" name="adres1" rules={[
							{ required: true, message: "Gelieve uw adres in te vullen", whitespace: true, max: 128 },
							{ pattern: /[0-9]+/, message: "Gelieve uw huisnummer in te vullen" }
						]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Postcode" name="postcode" rules={[{ required: true, message: "Gelieve uw postcode in te vullen", whitespace: true, max: 10 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Gemeente" name="gemeente" rules={[{ required: true, message: "Gelieve uw gemeente in te vullen", whitespace: true, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Land" name="land" rules={[{ required: true, message: "Gelieve uw land in te vullen" }]}>
							<Select>
								{landen.map(land => <Select.Option key={land} value={land}>{land}</Select.Option>)}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Row gutter={16}>
							<Col span={16}>
								<Form.Item label="E-mail" name="email" rules={[
									{ type: "email", message: "Gelieve een geldig emailadres in te vullen" },
									{ required: true, message: "Gelieve uw emailadres in te vullen", max: 128 }
								]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label="Telefoon" name="telefoon" rules={[{ required: false, max: 64 }]}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Gsm-nummer" name="gsm" rules={[{ required: false, max: 15 }]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label="SLE mag mijn contactgegevens doorgeven aan mensen die rasdieren zoeken" name="akkoord_adres_doorgeven" rules={[{ required: true, message: "Gelieve een keuze te maken" }]}>
									<Select showSearch={true} onSearch={(e) => console.log(e)}>
										<Select.Option key="1" value="1">Ja</Select.Option>
										<Select.Option key="0" value="0">Nee</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label="Hoe SLE leren kennen?" name="lerenkennen" rules={[{ required: false, max: 256 }]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={8}>
					</Col>
				</Row>
				<Row>
					<Button type="primary" htmlType="submit" disabled={asyncUpdateState.isRunning}>Opslaan</Button>
				</Row>
			</Form>
		</Fragment>
	);
};
