
import { Form, Input, InputNumber, Modal, notification, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { memberActions } from "../../store/member";
import { getAsyncUiState } from "../../store/ui";

export const RegisterPaymentModal = (props: { memberId: number, onClose: () => void }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [bedragDisabled, setBedragDisabled] = useState<boolean>(true);
	const [asyncPaymentState, resetAsyncPaymentState] = getAsyncUiState(memberActions.registerSubscriptionPayment.toString());
	
	useEffect(
		() => {
			if (asyncPaymentState.isRunning) {
				notification.info({ key: "payment", message: "Even geduld", description: "De betaling wordt verwerkt" });
			}

			if (asyncPaymentState.hasCompleted) {
				if (asyncPaymentState.error) {
					notification.error({ key: "payment", message: "Oeps...", description: "Fout: " + asyncPaymentState.error.toString() });
				} else {
					props.onClose();
					form.resetFields();
					setBedragDisabled(true);
					notification.success({ key: "payment", message: "Ok", description: "De betaling werd verwerkt" });
				}

				resetAsyncPaymentState();
			}
		},
		[asyncPaymentState]
	);


	function changeBetalingType(e: RadioChangeEvent) {
		if (e.target.value !== "betalend") {
			form.setFieldsValue({ bedrag: 0 });
			setBedragDisabled(true);
		} else {
			setBedragDisabled(false);
		}
	}

	function submitBetaling() {
		form
			.validateFields()
			.then(values => {
				dispatch(memberActions.registerSubscriptionPayment({ mid: props.memberId, values }));
			})
			.catch(err => {
				notification.error({ key: "betaling", message: "Oeps...", description: "Fout: " + err.toString() });
			});
	}

	return (
		<Modal visible={props.memberId > 0} title="Betaling ingeven" onOk={submitBetaling} onCancel={() => props.onClose()} cancelText="Annuleren">
			<Form form={form} layout="vertical">
				<Form.Item label="Type" name="type" rules={[{ required: true }]}>
					<Radio.Group onChange={changeBetalingType}>
						<Radio value="ruil">Ruil</Radio>
						<Radio value="gratis">Gratis</Radio>
						<Radio value="betalend">Betalend</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label="Bedrag" name="bedrag">
					<InputNumber disabled={bedragDisabled} />
				</Form.Item>
				<Form.Item label="Einde lidmaatschap" name="einde" rules={[{ pattern: /^[0-9]{4}-[0-4]{1}$/, message: "Vul een geldig einde in, formaat: 0000-0" }]}>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
}