import { Alert, Spin } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../store";
import { mijnActions } from "../../store/mijn";

interface FokkerComponentProps {
    breeder: any;

    loadBreeder: () => void;
}

class FokkerComponent extends React.Component<FokkerComponentProps> {

    componentDidMount() {
        this.props.loadBreeder();
    }

    render() {

        if (!this.props.breeder) {
            return (
                <div>
                    <h2>Mijn fokkerij</h2>
                    <Spin />
                </div>
            );
        }

        if (isEmpty(this.props.breeder)) {
            return (
                <div>
                    <h2>Mijn fokkerij</h2>
                    <p>U bent bij Steunpunt Levend Erfgoed vzw niet gekend als fokker van levend erfgoed rassen.</p>
                </div>
            );
        }

        return (
            <div>
                <h2>Mijn fokkerij</h2>
                <Alert type="info" message="U kan deze gegevens momenteel enkel raadplegen. De mogelijkheid om uw gegevens zelf aan te passen komt later." />
                <div>
                    <p>Stamboekcode: {this.props.breeder.stamboekcode}</p>
                    <p>Fokkernummer: {this.props.breeder.fokkernummer}</p>
                    <p>Sanitel: {this.props.breeder.sanitel}</p>
                    <p>Vbn: {this.props.breeder.vbn}</p>
                    <p>Dieren: {this.props.breeder.dieren}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => {
    return {
        breeder: state.mijn.breeder
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadBreeder: () => {
            return dispatch(mijnActions.loadBreeder());
        }
    };
};

export const Fokker = connect(mapStateToProps, mapDispatchToProps)(FokkerComponent);
