import { Col, Row, Statistic } from "antd";
import { chunk, map } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { vereenvoudigdBeheerActions } from "../../store/vereenvoudigdBeheer";

export function Dashboard() {

	const dispatch = useDispatch();

	useEffect(
		() => {
			console.log("ok");
			dispatch(vereenvoudigdBeheerActions.loadOverview());
		},
		[false]
	);

	const { overviewData } = useSelector((state: StoreState) => ({
		overviewData: state.vereenvoudigdBeheer.overview || []
	}));

	const rows: React.ReactNode[] = map(chunk(overviewData, 4), row => (
		<Row gutter={16}>
			{map(row, col => (<Col span={6}><Statistic title={col.naam} value={col.aantal} /></Col>))}
		</Row>
	))

	return (
		<div>
			<h2>Vereenvoudigd beheer</h2>
			{rows}
		</div>
	);
}
