import { Form } from "@ant-design/compatible";
import { Button, Card, Input, List, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { filter, isEmpty, NumericDictionary } from "lodash";
import React, { FormEvent, Fragment } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../store";
import { Ras, vereenvoudigdBeheerActions } from "../../store/vereenvoudigdBeheer";
import { Kleuren } from "./Kleuren";
import { RasSwitch } from "./RasSwitch";

export type RassenLijstProps = RassenLijstStaticProps & RassenLijstStoreProps & RassenLijstDispatchProps & FormComponentProps;

interface RassenLijstStaticProps {
    type: "actieve" | "inactieve";
}

interface RassenLijstStoreProps {
    rassen: Ras[];
    kleuren: NumericDictionary<string[]>;
}

interface RassenLijstDispatchProps {
    updateRas(ras: Ras): Promise<void>;
}

interface RassenLijstState {
    filter?: string;
}

class RassenLijstComponent extends React.Component<RassenLijstProps, RassenLijstState> {

    constructor(props: RassenLijstProps) {
        super(props);

        this.state = {};
    }

    filterRassen(): Ras[] {
        let filtered = filter(this.props.rassen, ras => ras.actief === (this.props.type === "actieve"));
        if (!isEmpty(this.state.filter)) {
            const regExp = new RegExp(this.state.filter || ".*", "i");
            filtered = filter(filtered, ras => regExp.test(ras.naam));
        }

        return filtered;
    }

    applyFilter(e: FormEvent<any>) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                message.error(err);
            } else {
                this.setState({
                    filter: values.naam
                });
            }
        });
    }

    async toggleRas(ras: Ras): Promise<void> {
        return this.props.updateRas(ras);
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Fragment>
                <Form layout="inline" style={{ marginBottom: "16px" }} onSubmit={e => this.applyFilter(e)}>
                    <Form.Item>
                        {getFieldDecorator("naam", { rules: [], initialValue: this.state.filter })(<Input placeholder="Naam" />)}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" icon="search" />
                    </Form.Item>
                </Form>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 4,
                        xxl: 4
                    }}
                    dataSource={this.filterRassen()}
                    renderItem={item => (
                        <List.Item>
                            <Card actions={[<Kleuren ras={item} />, <RasSwitch key={item.id} ras={item} onChange={ras => this.toggleRas(ras)} />]}>
                                <Card.Meta title={item.naam} />
                            </Card>
                        </List.Item>
                    )}
                />
            </Fragment>
        );
    }
}

export function mapStateToProps(store: StoreState, ownProps: RassenLijstStaticProps & FormComponentProps): RassenLijstStoreProps {
    return {
        rassen: store.vereenvoudigdBeheer.rassen,
        kleuren: store.vereenvoudigdBeheer.kleuren
    };
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        updateRas: async (ras: Ras): Promise<void> => {
            await dispatch(vereenvoudigdBeheerActions.updateRas(ras));
        }
    };
}

export const RassenLijst = connect(mapStateToProps, mapDispatchToProps)(Form.create<RassenLijstProps>()(RassenLijstComponent));
