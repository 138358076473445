
import axios from "axios";
import { InvoerData } from "../components/my/vereenvoudigd-beheer/interfaces";
import { config } from "../config";
import { MijnVereenvoudigdBeheer } from "../store/mijn";

export async function getMemberData(accessToken: string): Promise<any> {
	const response = await axios.get(config.apiUri + "/me", { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function createMemberData(accessToken: string, data: any): Promise<any> {
	const response = await axios.post(config.apiUri + "/me", data, { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function updateContactGegevens(accessToken: string, data: any): Promise<any> {
	const response = await axios.put(config.apiUri + "/me", data, { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function getSubscriptionData(accessToken: string): Promise<any> {
	const response = await axios.get(
		config.apiUri + "/me/subscription",
		{ headers: { authorization: "Bearer " + accessToken } }
	);
	return response.data;
}

export async function createSubscriptionPayment(accessToken: string, data: { amount: number, redirectUri: string })
	: Promise<{ redirectUri: string }> {
	const response = await axios.post(
		config.apiUri + "/me/subscription/payment",
		{ amount: data.amount, redirectUri: data.redirectUri },
		{ headers: { authorization: "Bearer " + accessToken } }
	);
	return response.data;
}

export async function getSubscriptionPayment(accessToken: string, pid: string): Promise<any> {
	const response = await axios.get(config.apiUri + "/payments/me/" + pid, { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function getBreederData(accessToken: string): Promise<any> {
	const response = await axios.get(config.apiUri + "/me/breeder", { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function getVereenvoudigdBeeerData(accessToken: string): Promise<any> {
	const response = await axios.get(config.apiUri + "/me/vereenvoudigd-beheer", { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function updateVereenvoudigdBeheerData(accessToken: string, data: MijnVereenvoudigdBeheer): Promise<void> {
	const response = await axios.post(config.apiUri + "/me/vereenvoudigd-beheer", data, { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function loadVereenvoudigdBeheerData(accessToken: string): Promise<InvoerData[]> {
	const response = await axios.get(config.apiUri + "/me/vereenvoudigd-beheer/data", { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function getLaatsteVereenvoudigdBeheerInvoer(accessToken: string): Promise<any[]> {
	const response = await axios.get(config.apiUri + "/me/vereenvoudigd-beheer/data/latest", { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function sendVereenvoudigdBeheerData(accessToken: string, data: InvoerData): Promise<void> {
	const response = await axios.post(config.apiUri + "/me/vereenvoudigd-beheer/data", data, { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}

export async function bulkInsertVereenvoudigdBeheerData(accessToken: string, data: InvoerData[]): Promise<void> {
	const response = await axios.post(config.apiUri + "/me/vereenvoudigd-beheer/data/bulk", data, { headers: { authorization: "Bearer " + accessToken } });
	return response.data;
}
