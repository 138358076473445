import { Layout, Menu } from "antd";
import React from "react";
import { connect } from "react-redux";
import { NavLink, Route, Switch } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { Rapporten } from "./Rapporten";
import { Rassen } from "./Rassen";

export interface VereenvoudigdBeheerProps {
    basePath: string;
}

class VereenvoudigdBeheerComponent extends React.Component<VereenvoudigdBeheerProps> {
    render() {
        return (
            <Layout>
                <Layout.Sider width={200} style={{ background: "#fff" }}>
                    <Menu>
                        <Menu.Item key="dashboard"><NavLink to={this.props.basePath}>Overzicht</NavLink></Menu.Item>
                        <Menu.Item key="rassen"><NavLink to={this.props.basePath + "/rassen"}>Rassen</NavLink></Menu.Item>
                        <Menu.Item key="rapporten"><NavLink to={this.props.basePath + "/rapporten"}>Rapporten</NavLink></Menu.Item>
                    </Menu>
                </Layout.Sider>
                <Layout style={{ padding: "24px 24px 0 24px" }}>
                    <Layout.Content style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}>
                        <Switch>
                            <Route key="dashboard" path={this.props.basePath} exact component={Dashboard} />
                            <Route key="rassen" path={this.props.basePath + "/rassen"} component={Rassen} />
                            <Route key="rapporten" path={this.props.basePath + "/rapporten"} component={Rapporten} />
                        </Switch>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

export const VereenvoudigdBeheer = connect()(VereenvoudigdBeheerComponent);
