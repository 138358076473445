
import { Icon } from "@ant-design/compatible";
import { Button, Empty, List, Modal } from "antd";
import { isEmpty } from "lodash";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store";
import { Ras } from "../../store/vereenvoudigdBeheer";

export function Kleuren(props: { ras: Ras }) {

	const [visible, setVisible] = useState<boolean>(false);

	const kleuren = useSelector((state: StoreState) => state.vereenvoudigdBeheer.kleuren[props.ras.id] || []);

	const content = isEmpty(kleuren)
		? (<Empty description="Geen kleuren beschikbaar voor dit ras." />)
		: (
			<List
				size="small"
				dataSource={kleuren}
				renderItem={item => (
					<List.Item>{item.kleur}</List.Item>
				)}
			/>
		);

	return (
		<Fragment>
			<Icon type="bg-colors" onClick={() => setVisible(true)} />

			<Modal
				key={props.ras.id}
				title={props.ras.naam}
				visible={visible}
				onCancel={() => setVisible(false)}
				footer={[<Button type="primary" onClick={() => setVisible(false)}>OK</Button>]}>
				{content}
			</Modal>
		</Fragment>
	);
}
