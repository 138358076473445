
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Result, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { StoreState } from "../../../store";
import { mijnActions } from "../../../store/mijn";

interface StatusLidmaatschapProps {
    basePath: string;
}

export const StatusLidmaatschap = (props: StatusLidmaatschapProps) => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            dispatch(mijnActions.getLidmaatschap());
        },
        [false]
    );

    const { member, subscription } = useSelector((state: StoreState) => ({
        member: state.mijn.member || {},
        subscription: state.mijn.subscription
    }));

    useEffect(
        () => {
            setLoading(loading ? false : true);
        },
        [subscription]
    );

    if (loading) {
        return (
            <Spin />
        );
    }

    const hasContactData = member && !isEmpty(member);
    const hasMembership = subscription && !isEmpty(subscription);

    if (!hasContactData) {
        return (<Result
            status="info"
            icon={<ExclamationCircleOutlined />}
            title="Status lidmaatschap"
            subTitle="Om lid te worden hebben we eerst uw contactgegevens nodig."
            extra={
                <NavLink to={props.basePath + "/contact"}>
                    <Button type="primary">Contactgegevens invullen</Button>
                </NavLink>
            }
        />);
    } else if (!hasMembership) {
        return (
            <Result
                status="error"
                icon={<CloseCircleOutlined />}
                title="Status lidmaatschap"
                subTitle="U bent nog geen lid van Steunpunt Levend Erfgoed"
                extra={
                    <NavLink to={props.basePath + "/lidgeld-betalen"}>
                        <Button type="primary">Lid worden</Button>
                    </NavLink>
                }
            />
        );
    } else if (subscription.status === "inactief") {
        return (
            <Result
                status="error"
                icon={<CloseCircleOutlined />}
                title="Status lidmaatschap"
                subTitle="U was eerder lid van Steunpunt Levend Erfgoed maar uw lidmaatschap is vervallen"
                extra={
                    <Fragment>
                        <NavLink to={props.basePath + "/lidmaatschap"}>
                            <Button type="default">Status lidmaatschap bekijken</Button>
                        </NavLink>
                    </Fragment>
                }
            />
        );
    } else if (subscription.status === "te-vernieuwen") {
        return (
            <Result
                status="warning"
                icon={<ExclamationCircleOutlined />}
                title="Status lidmaatschap"
                subTitle="Uw lidmaatschap van Steunpunt Levend Erfgoed moet vernieuwd worden"
                extra={
                    <Fragment>
                        <NavLink to={props.basePath + "/lidmaatschap"}>
                            <Button type="default">Status lidmaatschap bekijken</Button>
                        </NavLink>
                        <NavLink to={props.basePath + "/lidgeld-betalen"}>
                            <Button type="primary">Lidgeld betalen</Button>
                        </NavLink>
                    </Fragment>
                }
            />
        );
    } else {
        return (
            <Result
                status="success"
                icon={<CheckCircleOutlined />}
                title="Status lidmaatschap"
                subTitle="U bent lid van Steunpunt Levend Erfgoed"
                extra={
                    <NavLink to={props.basePath + "/lidmaatschap"}>
                        <Button type="primary">Status lidmaatschap bekijken</Button>
                    </NavLink>
                }
            />
        );
    }
}
