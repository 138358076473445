import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { userManager } from "../userManager";

export class Login extends React.Component<RouteComponentProps<{}>> {
    render() {
        let returnTo = "/mijn";
        if (this.props.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search);
            returnTo = urlParams.get("returnTo") || "/mijn";
        }

        userManager.signinRedirect({ data: { returnTo: returnTo } });

        return (<div>Log in...</div>);
    }
}
