
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { StoreState } from "../../../store";
import { mijnActions } from "../../../store/mijn";

interface StatusContactgegevensProps {
    basePath: string;
}

export const StatusContactgegevens = (props: StatusContactgegevensProps) => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(mijnActions.getContactGegevens());
        },
        [false]
    );

    const { member } = useSelector((state: StoreState) => ({
        member: state.mijn.member
    }));

    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            setLoading(loading ? false : true);
        },
        [member]
    );

    if (loading) {
        return (
            <Spin />
        );
    }

    const hasContactData = member && !isEmpty(member);

    if (!hasContactData) {
        return (
            <Result
                status="error"
                icon={<CloseCircleOutlined />}
                title="Fiche contactgegevens"
                subTitle="We hebben uw contactgegevens niet teruggevonden in onze databank."
                extra={
                    <NavLink to={props.basePath + "/contact"}>
                        <Button type="primary">Invullen</Button>
                    </NavLink>
                }
            />
        );
    } else {
        return (
            <Result
                status="success"
                icon={<CheckCircleOutlined />}
                title="Fiche contactgegevens"
                subTitle="We hebben uw contactgegevens teruggevonden in onze databank."
                extra={
                    <NavLink to={props.basePath + "/contact"}>
                        <Button type="primary">Bekijken of wijzigen</Button>
                    </NavLink>
                }
            />
        );
    }
}