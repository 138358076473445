import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table } from 'antd';
import { Dispatch } from 'redux';
import { has, extend } from 'lodash';

import { StoreState } from '../../store';
import { memberActions } from '../../store/member';

interface LogboekComponentProps {
    mid: number;
    eventsLoaded: boolean;
    events: Array<any>;
    loadEvents: (mid: number) => void;
}

class LogboekComponent extends React.Component<LogboekComponentProps> {

    componentDidMount() {
        this.props.loadEvents(this.props.mid);
    }

    render() {
        if (!this.props.eventsLoaded) {
            return <div>Loading</div>;
        }

        const eventColumns = [
            {
                title: 'Gebeurtenis',
                key: 'type',
                dataIndex: 'type'
            },
            {
                title: 'Tijdstip',
                key: 'timestamp',
                render: (text: string, record: any) => {
                    return moment(record.timestamp).format('dd, DD/MM/YYYY HH:mm:ss');
                }
            },
            {
                title: 'Data',
                key: 'data',
                render: (text: string, record: any) => {
                    return JSON.stringify(record.data);
                }
            }
        ];

        return (<Table size="middle" columns={eventColumns} rowKey="timestamp" dataSource={this.props.events} />);
    }
}

const mapStateToProps = (state: StoreState, ownProps: any): any => {
    return extend(
        {},
        ownProps,
        {
            eventsLoaded: has(state.member.events, ownProps.mid),
            events: state.member.events[ownProps.mid]
        }
    );
};

const mapDispatchToProps = (dispatch: Dispatch): any => {
    return {
        loadEvents: (mid: number) => {
            dispatch(memberActions.findEvents(mid));
        }
    }
}

export const Logboek = connect(mapStateToProps, mapDispatchToProps)(LogboekComponent);