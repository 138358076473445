
import { Button, Form, Input, Modal, notification, Switch } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { qrActions, QrCode } from "../../store/qr";
import { getAsyncUiState } from "../../store/ui";

export const QrCodeModal = () => {
	const dispatch = useDispatch();

	const { modal } = useSelector((state: StoreState) => ({
		modal: state.qrCodes.modal
	}));

	const [asyncAction, resetAsyncAction] = getAsyncUiState(modal.actionCreator.toString());

	useEffect(
		() => {
			if (asyncAction.hasCompleted) {
				if (asyncAction.error) {
					notification.error({ key: modal.qrCode.id.toString(), message: "Oeps...", description: "Fout: " + asyncAction.error.toString() });
				} else {
					notification.close(modal.qrCode.id.toString());
					resetAsyncAction();
					dispatch(qrActions.closeModal());
				}
			}
		},
		[asyncAction.isRunning]
	);

	const [form] = Form.useForm();
	form.setFieldsValue(modal.qrCode);

	function onCancel() {
		dispatch(qrActions.closeModal());
	}

	function onOk() {
		form
			.validateFields()
			.then(values => {
				dispatch(modal.actionCreator(values as QrCode));
			});
	}

	return (
		<Modal
			visible={modal.visible}
			title={modal.title}
			footer={[
				<Button key="cancel" onClick={onCancel}>Cancel</Button>,
				<Button key="ok" type="primary" onClick={onOk} loading={asyncAction.isRunning} disabled={asyncAction.isRunning}>OK</Button>
			]}
		>
			<Form form={form} layout="vertical">
				<Form.Item name="id" noStyle={true}>
					<Input hidden={true} />
				</Form.Item>
				<Form.Item label="Naam" rules={[{ required: true, message: "Gelieve een naam op te geven" }]} name="name">
					<Input placeholder="naam" maxLength={128} />
				</Form.Item>
				<Form.Item label="Link" name="link" rules={[{ required: true, message: "Gelieve een link op te geven" }]}>
					<Input placeholder="link" maxLength={1024} />
				</Form.Item>
				<Form.Item label="Actief" name="enabled" valuePropName="checked">
					<Switch />
				</Form.Item>
			</Form>
		</Modal>
	);
};
