
import { Col, List, Row, Typography } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store";
import { StatusContactgegevens } from "./status/FicheContactGegevens";
import { StatusLidmaatschap } from "./status/Lidmaatschap";
import { StatusVereenvoudigdBeheer } from "./status/VereenvoudigdBeheer";

const { Text } = Typography;

export interface OverzichtProps {
    basePath: string;
}

export const Overzicht = (props: OverzichtProps) => {

    const name = useSelector((state: StoreState) => state.userData.profile.name);

    /* TODO: from db */
    const berichten = [
        {
            title: "Fiche contactgegevens",
            message: (
                <Text>
                    Het kan zijn dat u al lid bent van Steunpunt Levend Erfgoed maar dat we uw lidmaatschap nog moeten
                koppelen aan uw fiche contactgegevens. Bent u al lid maar staat hierboven aangegeven dat we nog geen contactgegevens van u hebben, stuur dan
                een e-mail naar info@sle.be met vermelding van uw gebruikersnaam ( {name} ) en uw volledige naam en adres. Een medewerker van SLE 
                zal uw gegevens nakijken en de koppeling maken.
                </Text>
            )
        },
        {
            title: "Vereenvoudigd beheer",
            message: (
                <Text>
                    Voer uw aantallen neerhofdieren in dit fokkerijbeheer in. Het zorgt voor algemene data die we kunnen gebruiken in de bescherming 
					van ons Levend Erfgoed. Uw privacy wordt gerespecteerd en uw gegevens worden niet vrijgegeven aan derden tenzij u expliciet meldt 
					dat wij uw contactgegevens mogen doorgeven aan mensen die een bepaald ras zoeken.
                </Text>
            )
        }
    ];

    return (
        <Fragment>
            <h2>Welkom {name}</h2>
            <Row gutter={16}>
                <Col span={8}>
                    <StatusContactgegevens basePath={props.basePath} />
                </Col>
                <Col span={8}>
                    <StatusLidmaatschap basePath={props.basePath} />
                </Col>
                <Col span={8}>
                    <StatusVereenvoudigdBeheer basePath={props.basePath} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <h3>Berichten</h3>
                    <List
                        itemLayout="horizontal"
                        dataSource={berichten}
                        renderItem={bericht => (
                            <List.Item>
                                <List.Item.Meta
                                    title={bericht.title}
                                    description={bericht.message}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Fragment>
    );

};
