

import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Result, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { StoreState } from "../../../store";
import { mijnActions } from "../../../store/mijn";

interface StatusVereenvoudigdBeheerProps {
    basePath: string;
}

export const StatusVereenvoudigdBeheer = (props: StatusVereenvoudigdBeheerProps) => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            dispatch(mijnActions.getDataVereenvoudigdBeheer());
        },
        [false]
    );

    const { member, vereenvoudigdBeheer } = useSelector((state: StoreState) => ({
        member: state.mijn.member || {},
        vereenvoudigdBeheer: state.mijn.vereenvoudigdBeheer
    }));

    useEffect(
        () => {
            setLoading(loading ? false : true);
        },
        [vereenvoudigdBeheer]
    );

    if (loading) {
        return (
            <Spin />
        );
    }

    const hasContactData = member && !isEmpty(member);
    const hasVereenvoudigdBeheer = vereenvoudigdBeheer && !isEmpty(vereenvoudigdBeheer) && vereenvoudigdBeheer.deelnemer;

    if (!hasContactData) {
        return (
            <Result
                status="info"
                icon={<ExclamationCircleOutlined />}
                title="Vereenvoudigd beheer"
                subTitle="Om deel te nemen aan het vereenvoudigd beheer hebben we eerst uw contactgegevens nodig."
                extra={
                    <NavLink to={props.basePath + "/contact"}>
                        <Button type="primary">Contactgegevens invullen</Button>
                    </NavLink>
                }
            />
        );
    } else if (!hasVereenvoudigdBeheer) {
        return (
            <Result
                status="error"
                icon={<CloseCircleOutlined />}
                title="Vereenvoudigd beheer"
                subTitle="U neemt niet deel aan het vereenvoudigd beheer"
                extra={
                    <NavLink to={props.basePath + "/vereenvoudigd-beheer"}>
                        <Button type="primary">Deelnemen</Button>
                    </NavLink>
                }
            />
        );
    } else {
        return (
            <Result
                status="success"
                icon={<CheckCircleOutlined />}
                title="Vereenvoudigd beheer"
                subTitle="U neemt deel aan het vereenvoudigd beheer"
                extra={
                    <NavLink to={props.basePath + "/vereenvoudigd-beheer"}>
                        <Button type="primary">Verder naar vereenvoudigd beheer</Button>
                    </NavLink>
                }
            />
        );
    }
}
