import { extend } from "lodash";
import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { StoreState } from ".";
import { findWebUsers } from "../api/website";

export interface WebsiteState {
    webusers: any[];
}

const initialState: WebsiteState = {
    webusers: []
};

export const websiteActions = {
    loadWebUsers: (): AnyAction => {
        return { type: "WEB_USERS_LOAD" };
    }
};

export function websiteReducer(state: WebsiteState = initialState, action: AnyAction): WebsiteState {
    if (!action) {
        return extend({}, state);
    }

    switch (action.type) {
        case "WEB_USERS_LOADED":
            return extend({}, state, { webusers: action.payload });
        default:
            return extend({}, state);
    }
}

export function* websiteSaga() {
    yield takeLatest("WEB_USERS_LOAD", webUsersLoadSaga);
}

function* webUsersLoadSaga(action: AnyAction) {
    const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
    const result = yield call(findWebUsers, accessToken);
    yield put({ type: "WEB_USERS_LOADED", payload: result });
}
