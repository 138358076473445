
import { DownloadOutlined } from "@ant-design/icons";
import { Button, notification, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import download from "js-file-download";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { StoreState } from "../../store";
import { vereenvoudigdBeheerActions } from "../../store/vereenvoudigdBeheer";

export interface RapportenComponentProps {
	downloadAlleData: () => Promise<AnyAction>;
	downloadOverviewData: () => Promise<AnyAction>;
}

class RapportenComponent extends React.Component<RapportenComponentProps> {

    downloadAlleDataRapport() {
        notification.info({ message: "Even geduld", description: "Het rapport wordt aangemaakt..." });
        this.props.downloadAlleData()
            .then(action => {
                download(action.data, action.filename, action.mime);
            })
            .catch(err => {
                notification.error({ message: "Fout", description: "Sorry fout bij het aanmaken van het rapport (" + err.toString() + ")" });
            });
	}
	
	downloadOverviewDataRapport() {
		notification.info({ message: "Even geduld", description: "Het rapport wordt aangemaakt..." });
        this.props.downloadOverviewData()
            .then(action => {
                download(action.data, action.filename, action.mime);
            })
            .catch(err => {
                notification.error({ message: "Fout", description: "Sorry fout bij het aanmaken van het rapport (" + err.toString() + ")" });
            });
	}

    render() {

        const columns: Array<ColumnProps<any>> = [
            {
                title: "Titel",
                dataIndex: "title"
            },
            {
                title: "Omschrijving",
                dataIndex: "description"
            },
            {
                title: "",
                dataIndex: "report",
                render: (text: any, record: any, index: any): React.ReactNode => {
                    return (<Button shape="circle" icon={<DownloadOutlined />} onClick={record.func} />);
                }
            }
        ];

        const data: any[] = [
            {
                title: "Alle data",
                description: "Rapport met alle door fokkers ingevoerde aantallen",
                report: "alle-data",
                func: this.downloadAlleDataRapport.bind(this)
			},
			{
				title: "Overzicht",
				description: "Rapport met een overzicht van de meest belangrijke gegevens",
				report: "overview",
				func: this.downloadOverviewDataRapport.bind(this)
			}
        ];

        return (
            <Fragment>
                <h2>Rapporten</h2>
                <Table columns={columns} dataSource={data} rowKey="title" pagination={false} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state: StoreState): {} => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch): RapportenComponentProps => {
    return {
        downloadAlleData: (): Promise<AnyAction> => {
            return dispatch(vereenvoudigdBeheerActions.downloadAlleDataRapport());
		},
		downloadOverviewData: (): Promise<AnyAction> => {
			return dispatch(vereenvoudigdBeheerActions.downloadOverviewDataRapport());
		}
    };
};

export const Rapporten = connect(mapStateToProps, mapDispatchToProps)(RapportenComponent);
