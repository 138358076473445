
import { PlusOutlined } from "@ant-design/icons";
import { Badge, Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import download from "js-file-download";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { StoreState } from "../../store";
import { qrActions, QrCode } from "../../store/qr";
import { getAsyncUiState } from "../../store/ui";
import { QrCodeModal } from "./QrCodeModal";
import { QrCodeToolbar } from "./QrCodeToolbar";

const columns: ColumnsType<QrCode> = [
	{
		title: "Naam",
		key: "name",
		defaultSortOrder: "ascend",
		sorter: (a: QrCode, b: QrCode) => (a.name || "").toLocaleLowerCase() < (b.name || "").toLocaleLowerCase() ? -1 : 1,
		render: (text: any, record: QrCode) => {
			const status = record.enabled ? "success" : "default";
			return (<Badge status={status} text={record.name} />);
		},
		width: "300px"
	},
	{
		title: "Link",
		key: "link",
		render: (text: any, record: QrCode) => {
			return (<a href={record.link} target="_blank">{record.link}</a>);
		}
	},
	{
		render: (text: any, record: QrCode) => {
			return (<QrCodeToolbar qrCode={record} />);
		},
		width: "200px"
	}
];

export const List = () => {
	const dispatch = useDispatch();
	const [asyncDownload, resetAsyncDownload] = getAsyncUiState(qrActions.download.toString());
	const [asyncDelete, resetAsyncDelete] = getAsyncUiState(qrActions.delete.toString());

	useEffect(
		() => {
			if (asyncDownload.isRunning) {
				notification.info({ key: "download", message: "Even geduld", description: "De afbeelding wordt aangemaakt" })
			}

			if (asyncDownload.hasCompleted) {
				if (asyncDownload.error || !asyncDownload.payload) {
					notification.error({ key: "download", message: "Oeps...", description: "Fout: " + (asyncDownload.error || new Error("No payload")).toString() });
				} else {
					notification.close("download");
					download(asyncDownload.payload.data, asyncDownload.payload.filename, asyncDownload.payload.mime);
					resetAsyncDownload();
				}
			}
		},
		[asyncDownload.isRunning]
	);

	useEffect(
		() => {
			if (asyncDelete.isRunning) {
				notification.info({ key: "delete", message: "Even geduld", description: "De QR code wordt verwijderd" });
			}

			if (asyncDelete.hasCompleted) {
				if (asyncDelete.error) {
					notification.error({ key: "delete", message: "Oeps...", description: "Fout: " + asyncDelete.error.toString() });
				} else {
					notification.close("delete");
					resetAsyncDelete();
				}
			}
		},
		[asyncDelete.isRunning]
	);

	useEffect(
		() => {
			dispatch(qrActions.load());
		},
		[false]
	);

	const { codes } = useSelector((state: StoreState) => ({
		codes: state.qrCodes.codes
	}));

	return (
		<Fragment>
			<Space direction="vertical" style={{ width: "100%" }}>
				<h2>QR codes</h2>
				<Button type="primary" icon={<PlusOutlined />} onClick={() => dispatch(qrActions.openCreateModal())} />
				<Table columns={columns} rowKey="id" dataSource={codes} />
			</Space>

			<QrCodeModal />
		</Fragment>
	);
};

interface ListComponentState {
	modalVisible: boolean;
	modalTitle?: string;
	modalCode?: QrCode;
	modalOkAction?: (name: string, link: string, enabled: boolean, id?: number) => void;
}

class ListComponent extends React.Component<any, ListComponentState> {

	confirmModal() {
		const me = this;
		this.props.form.validateFieldsAndScroll(async (err: any, values: any) => {
			if (!err) {
				notification.info({ message: "Even geduld...", description: "De code wordt opgeslaan." });
				try {
					if (me.state.modalOkAction) {
						await me.state.modalOkAction(values.name, values.link, values.enabled, values.id);
						notification.success({ message: "OK", description: "De code werd opgeslaan." });
						me.closeModal();
						this.props.loadQrCodes();
					} else {
						throw new Error();
					}
				} catch (okError) {
					notification.error({ message: "Oeps", description: "Er is iets fout gelopen..." });
				}
			}
		});
	}
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any): {} => {
	return {
		createQrCode: (name: string, link: string, enabled: boolean): Promise<AnyAction> => {
			return dispatch(qrActions.create(name, link, enabled));
		},
		updateQrCode: (name: string, link: string, enabled: boolean, id: number): Promise<AnyAction> => {
			return dispatch(qrActions.update(name, link, enabled, id));
		},
	};
};
