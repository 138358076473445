import { Button, notification, Spin } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../store";
import { memberActions } from "../../store/member";

export interface StatistiekComponentProps {
    statistiek: any;

    loadStatistiek: () => void;
    updateSubscriptionsToRenew: () => Promise<void>;
}

class StatistiekComponent extends React.Component<StatistiekComponentProps> {

    componentDidMount() {
        this.props.loadStatistiek();
    }

    updateSubscriptionsToRenew() {
        notification.info({ key: "statistiek", message: "Even geduld...", description: "De status van de te vernieuwen lidmaatschappen wordt bijgewerkt." });
        this.props.updateSubscriptionsToRenew()
            .then(() => {
                notification.success({ key: "statistiek", message: "Ok", description: "De status van de te vernieuwen lidmaatschappen werd bijgewerkt." });
                this.props.loadStatistiek();
            })
            .catch(() => {
                notification.error({ key: "statistiek", message: "Oeps...", description: "Er is iets fout gelopen, probeer opnieuw of neem contact op met SLE" });
            });
    }

    render() {
        if (isEmpty(this.props.statistiek)) {
            return (
                <div>
                    <h2>Ledenbeheer</h2>
                    <Spin />
                </div>
            );
        }

        const columns: Array<ColumnProps<any>> = [
            {
                title: "Titel",
                dataIndex: "title"
            },
            {
                title: "Waarde",
                dataIndex: "value"
            },
            {
                render: (text: any, record: any) => {
                    if (record.code === "subscription_to_renew") {
                        return (
                            <div>
                                <Button type="primary" onClick={() => this.updateSubscriptionsToRenew()}>Status bijwerken</Button>
                            </div>
                        );
                    }
                }
            }
        ];

        return (
            <div>
                <h2>Ledenbeheer</h2>
                <Table columns={columns} dataSource={this.props.statistiek} rowKey="title" pagination={false} />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): { statistiek: any } => {
    return {
        statistiek: state.member.statistiek
    };
};

const mapDispatchToProps = (dispatch: Dispatch): { loadStatistiek: () => any, updateSubscriptionsToRenew: () => any } => {
    return {
        loadStatistiek: () => {
            dispatch(memberActions.loadStatistiek());
        },
        updateSubscriptionsToRenew: () => {
            return dispatch(memberActions.updateSubscriptionsToRenew());
        }
    };
};

export const Statistiek = connect(mapStateToProps, mapDispatchToProps)(StatistiekComponent);
