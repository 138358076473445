import axios from "axios";
import { config } from "../config";
import { Kleur, Ras } from "../store/vereenvoudigdBeheer";
import { downloadFile } from "./reports";

export async function find(accessToken: string): Promise<Ras[]> {
	const response = await axios.get(config.apiUri + "/vereenvoudigd-beheer/rassen", { headers: { Authorization: "Bearer " + accessToken } });
	return response.data as Ras[];
}

export async function update(accessToken: string, ras: Ras): Promise<void> {
	await axios.post(
		config.apiUri + "/vereenvoudigd-beheer/rassen/" + ras.id,
		{ naam: ras.naam, actief: ras.actief },
		{ headers: { Authorization: "Bearer " + accessToken } }
	);
}

export async function listKleuren(accessToken: string): Promise<Kleur[]> {
	const response = await axios.get(config.apiUri + "/vereenvoudigd-beheer/kleuren", { headers: { Authorization: "Bearer " + accessToken } });
	return response.data as Kleur[];
}

export async function getAlleDataRapport(accessToken: string): Promise<any> {
	const response = await axios.get(
		config.apiUri + "/vereenvoudigd-beheer/reports/alle-data",
		{ headers: { authorization: "Bearer " + accessToken } }
	);

	return downloadFile(response.data.url, "alle-data.xlsx");
}

export async function getOverviewDataRapport(accessToken: string): Promise<any> {
	const response = await axios.get(
		config.apiUri + "/vereenvoudigd-beheer/reports/overview",
		{ headers: { authorization: "Bearer " + accessToken } }
	);

	return downloadFile(response.data.url, "overzicht.xlsx");
}

export async function getOverview(accessToken: string): Promise<Array<{ naam: string, aantal: number }>> {
	console.log("ok");
	const response = await axios.get(
		config.apiUri + "/vereenvoudigd-beheer/stats/overview",
		{ headers: { authorization: "Bearer " + accessToken } }
	);
	return response.data as Array<{naam: string, aantal: number}>;
}
