
import { Tabs } from "antd";
import React from "react";
import { LaatsteInvoer } from "./LaatsteInvoer";

export const Weergave = () => {
	return (
		<Tabs defaultActiveKey="grafiek">
			{/* <Tabs.TabPane tab="Grafiek" key="grafiek">
				<GrafiekEvolutie />
			</Tabs.TabPane> */}
			<Tabs.TabPane tab="Meest recente gegevens per ras en kleur" key="meest-recente">
				<LaatsteInvoer />
			</Tabs.TabPane>
		</Tabs>
	);
}
