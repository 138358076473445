import { put } from "@redux-saga/core/effects";
import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { StoreState } from ".";

export const actions = {
	startAsync: (name: string): AnyAction => {
		return {
			type: "ui/async/start",
			name: name,
			payload: undefined
		};
	},
	completeAsync: (name: string, err?: any, payload?: any): AnyAction => {
		return {
			type: "ui/async/complete",
			name: name,
			payload: payload,
			error: err
		};
	},
	resetAsync: (name: string): AnyAction => {
		return {
			type: "ui/async/reset",
			name: name,
			payload: undefined
		};
	}
};

export interface UiStoreState {
	[key: string]: AsyncUiState;
}

export interface AsyncUiState {
	isRunning: boolean;
	hasCompleted: boolean;
	error?: any;
	payload?: any;
};

const initialAsyncUiState: AsyncUiState = { isRunning: false, hasCompleted: false, error: undefined, payload: undefined };

const initialState: UiStoreState = {};

export function uiReducer(state: UiStoreState = initialState, action: AnyAction): UiStoreState {
	switch (action.type) {
		case "ui/async/start":
			return produce(state, draft => {
				draft[action.name] = { isRunning: true, hasCompleted: false, error: undefined, payload: action.payload };
			});
		case "ui/async/complete":
			return produce(state, draft => {
				draft[action.name] = { isRunning: false, hasCompleted: true, error: action.error, payload: action.payload };
			});
		case "ui/async/reset":
			return produce(state, draft => {
				draft[action.name] = { isRunning: false, hasCompleted: false, error: undefined, payload: action.payload };
			});
		default:
			return produce(state, draft => { });
	}
}

export function getAsyncUiState(name: string): [AsyncUiState, () => void] {
	const dispatch = useDispatch();

	return [
		useSelector((storeState: StoreState) => storeState.ui[name]) || initialAsyncUiState,
		() => {
			dispatch(actions.resetAsync(name));
		}
	];
}

export function* startAsyncUiAction(action: AnyAction) {
	if (action.uiAction) {
		yield put(actions.startAsync(action.uiAction));
	} else if (typeof action === "string") {
		yield put(actions.startAsync(action));
	}
}

export function* completeAsyncUiAction(action: AnyAction, error?: any, payload?: any) {
	if (action.uiAction) {
		yield put(actions.completeAsync(action.uiAction, error, payload));
	} else if (typeof action === "string") {
		yield put(actions.completeAsync(action, error, payload));
	}
}
