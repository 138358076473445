import { DeleteOutlined, DownloadOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Popconfirm, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { qrActions, QrCode } from "../../store/qr";

export const QrCodeToolbar = (props: { qrCode: QrCode }) => {

	const dispatch = useDispatch();

	function downloadQrCodeImage(type: string) {
		dispatch(qrActions.download(type, props.qrCode));
	}

	function editQrCode() {
		dispatch(qrActions.openEditModal(props.qrCode));
	}

	function deleteQrCode() {
		dispatch(qrActions.delete(props.qrCode.id));
	}

	const downloadMenu = (
		<Menu>
			<Menu.Item key="eps" onClick={() => downloadQrCodeImage("eps")}>eps</Menu.Item>
			<Menu.Item key="png" onClick={() => downloadQrCodeImage("png")}>png</Menu.Item>
		</Menu>
	);

	return (
		<Space direction="horizontal">
			<Dropdown key={props.qrCode.id} overlay={downloadMenu} trigger={["click"]}>
				<Button key="download" type="primary" icon={<DownloadOutlined />} />
			</Dropdown>
			<Button key="edit" type="default" icon={<EditOutlined />} onClick={editQrCode} />
			{!props.qrCode.enabled && (
				<Popconfirm key="delete"
					icon={<QuestionCircleOutlined style={{ color: "red" }} />}
					title="Deze qr-code schrappen?"
					onConfirm={deleteQrCode} okText="Ja" cancelText="Nee" placement="bottom">
					<Button key="delete" type="danger" icon={<DeleteOutlined />} />
				</Popconfirm>
			)}
		</Space>
	);
};
