import { Layout, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { UserState } from "redux-oidc";
import { StoreState } from "../../store";
import { UserProfile } from "../../store/userData";
import { CreateMember } from "./CreateMember";
import { Details } from "./Details";
import { MemberList } from "./MemberList";
import { Reports } from "./Reports";
import { Statistiek } from "./Statistiek";
import {SubscriptionPayments} from "./SubscriptionPayments";

interface MembersComponentProps extends RouteComponentProps<{}> {
	readonly basePath: string;
	readonly auth: UserState;
	readonly profile: UserProfile;
}

class MembersComponent extends Component<MembersComponentProps> {

	constructor(props: MembersComponentProps) {
		super(props);

		this.goToMemberDetails = this.goToMemberDetails.bind(this);
		this.goToMemberList = this.goToMemberList.bind(this);
	}

	goToMemberDetails(mid: number): void {
		this.props.history.push(this.props.basePath + "/" + mid.toString());
	}

	goToMemberList(): void {
		this.props.history.push(this.props.basePath + "/ledenlijst");
	}

	render() {
		if (!this.props.auth.user || !this.props.profile.hasAnyRole(["administrator", "ledenbeheer lezen"])) {
			return (<div>Unauthorized</div>);
		}

		const menuLinks = [];
		const routes = [];

		menuLinks.push(<Menu.Item key="statistiek"><NavLink to={this.props.basePath}>Overzicht</NavLink></Menu.Item>);
		routes.push(<Route key="statistiek" path={this.props.basePath} exact component={Statistiek} />);

		menuLinks.push(<Menu.Item key="overzicht"><NavLink to={this.props.basePath + "/ledenlijst"}>Ledenlijst</NavLink></Menu.Item>);
		routes.push(<Route key="overzicht" path={this.props.basePath + "/ledenlijst"} render={() => <MemberList goToMemberDetails={this.goToMemberDetails} />} />);

		if (this.props.profile.hasAnyRole(["ledenbeheer schrijven", "administrator"])) {
			menuLinks.push(<Menu.Item key="nieuw"><NavLink to={this.props.basePath + "/create"}>Nieuw lid</NavLink></Menu.Item>);
			routes.push(<Route key="nieuw" path={this.props.basePath + "/create"} component={CreateMember} />);
		}

		if (this.props.profile.hasAnyRole(["ledenbeheer schrijven", "administrator"])) {
			menuLinks.push(<Menu.Item key="betalingen"><NavLink to={this.props.basePath + "/betalingen"}>Betalingen lidgeld</NavLink></Menu.Item>);
			routes.push(<Route key="betalingen" path={this.props.basePath + "/betalingen"} component={SubscriptionPayments} />);
		}

		menuLinks.push(<Menu.Item key="reports"><NavLink to={this.props.basePath + "/rapporten"}>Rapporten</NavLink></Menu.Item>);
		routes.push(<Route key="reports" path={this.props.basePath + "/rapporten"} component={Reports} />);

		routes.push(<Route key="edit" path={this.props.basePath + "/:mid"} render={props => <Details {...props} goToMemberList={this.goToMemberList} />} />);

		return (
			<Layout>
				<Layout.Sider width={200} style={{ background: "#fff" }}>
					<Menu>
						{menuLinks}
					</Menu>
				</Layout.Sider>
				<Layout style={{ padding: "24px 24px 0 24px" }}>
					<Layout.Content style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}>
						<Switch>
							{routes}
						</Switch>
					</Layout.Content>
				</Layout>
			</Layout>
		);
	}
}

function mapStateToProps(state: StoreState) {
	return {
		auth: state.auth,
		profile: state.userData.profile
	};
}

export const Members = withRouter(connect(mapStateToProps)(MembersComponent));
