import { Form } from "@ant-design/compatible";
import { Alert, Button, Col, Input, InputNumber, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { has, isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../../store';
import { memberActions } from '../../store/member';


const FormItem = Form.Item;

interface FokkerComponentProps extends FormComponentProps {
    mid: number;
    breederLoaded: boolean;
    breeder: any;
    loadBreeder: (mid: number) => any;
    updateBreeder: (mid: number, breeder: any) => Promise<any>;
    editAllowed: boolean;
}

interface FokkerComponentState {
    status: 'none' | 'saving' | 'saved' | 'error';
}

class FokkerComponent extends React.Component<FokkerComponentProps, FokkerComponentState> {

    constructor(props: FokkerComponentProps) {
        super(props);
        this.state = {
            status: 'none'
        };
    }

    componentDidMount() {
        this.props.loadBreeder(this.props.mid);
    }

    onSubmit(e: React.FormEvent<any>) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                this.setState({ status: 'saving' });

                let breeder: any = {};
                if (values.sanitel) {
                    breeder.sanitel = values.sanitel;
                }

                if (values.stamboekcode && values.stamboekcode > 0) {
                    breeder.stamboekcode = values.stamboekcode;
                }

                if (values.fokkernummer) {
                    breeder.fokkernummer = values.fokkernummer;
                }

                if (values.vbn && values.vbn > 0) {
                    breeder.vbn = values.vbn;
                }

                if (values.dieren) {
                    breeder.dieren = values.dieren;
                }

                this.props.updateBreeder(this.props.mid, breeder)
                    .then(_ => {
                        this.setState({ status: 'saved' });
                        this.props.loadBreeder(this.props.mid);
                    })
                    .catch(_ => {
                        this.setState({ status: 'error' });
                    });
            }
        });
    }

    render() {
        if (!this.props.breederLoaded) {
            return <div>Loading...</div>
        }

        const registered = !isEmpty(this.props.breeder);
        const breeder = this.props.breeder || {};

        let statusMessage: React.ReactNode = null;
        switch (this.state.status) {
            case 'error':
                statusMessage = <Alert message="Fout bij het opslaan van de wijzigingen" type="error" />;
                break;
            case "saved":
                statusMessage = <Alert message="De wijzigingen werden opgeslaan" type="success" />;
                break;
            case "saving":
                statusMessage = <Alert message="De wijzigingen worden opgeslaan" type="info" />;
                break;
            default:
                if (!registered) {
                    statusMessage = <Alert type="info" message="Deze persoon is nog niet gekend als fokker." />;
                }
                else {
                    statusMessage = null;
                }
                break;
        }

        let actions: React.ReactNode = null;
        if (this.props.editAllowed) {
            actions =
                (<div>
                    <Button type="primary" htmlType="submit">Opslaan</Button>
                </div>);
        }

        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                {statusMessage}
                <Form onSubmit={this.onSubmit.bind(this)}>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Sanitel">
                                {getFieldDecorator(
                                    'sanitel',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: breeder.sanitel
                                    }
                                )(
                                    <Input />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Stamboekcode">
                                {getFieldDecorator(
                                    'stamboekcode',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: breeder.stamboekcode
                                    }
                                )(
                                    <InputNumber />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Fokkernummer">
                                {getFieldDecorator(
                                    'fokkernummer',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: breeder.fokkernummer
                                    }
                                )(
                                    <Input />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="VBN id">
                                {getFieldDecorator(
                                    'vbn',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: breeder.vbn
                                    }
                                )(
                                    <InputNumber />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <FormItem label="Dieren">
                                {getFieldDecorator(
                                    'dieren',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: breeder.dieren
                                    }
                                )(
                                    <Input.TextArea rows={5} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {actions}
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState, ownProps: any): any => {
    return {
        breederLoaded: has(state.member.breeders, ownProps.mid),
        breeder: state.member.breeders[ownProps.mid],
        editAllowed: state.userData.profile.hasAnyRole(['administrator', 'ledenbeheer beheren'])
    }
}

const mapDispatchToProps = (dispatch: Dispatch): any => {
    return {
        loadBreeder: (mid: number) => {
            dispatch(memberActions.getBreeder(mid));
        },
        updateBreeder: (mid: number, breeder: any) => {
            return dispatch(memberActions.updateBreeder(mid, breeder));
        }
    }
}

export const Fokker = connect(mapStateToProps, mapDispatchToProps)(Form.create()(FokkerComponent));