
declare var __CONFIG__: { apiUri: string, clientUri: string };

export const config = {
    subscriptionPaymentRedirect: __CONFIG__.clientUri + "/mijn/lidgeld-betalen/status",
    auth: {
        authority: "https://sle.be/oauth2",
        client_id: "mijn.sle.be",
        redirect_uri: __CONFIG__.clientUri + "/callback",
        response_type: "token",
        scope: "openid profile email",
        metadata: {
            issuer: "",
            authorization_endpoint: "https://sle.be/oauth2/authorize",
            userinfo_endpoint: __CONFIG__.apiUri + "/userinfo",
            end_session_endpoint: "https://api.sle.be/logout",
            jwks_uri: ""
        },
        loadUserInfo: true,
        silent_redirect_uri: __CONFIG__.clientUri + "/silent.html",
        automaticSilentRenew: true
    },
    apiUri: __CONFIG__.apiUri
};
