import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, notification, Popconfirm, Row, Select } from "antd";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { dataActions } from "../../store/data";
import { memberActions } from "../../store/member";
import { getAsyncUiState } from "../../store/ui";

export const ContactGegevens = (props: { member: any, goToMemberList: () => void }) => {

	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const [asyncUpdateState, resetAsyncUpdateState] = getAsyncUiState(memberActions.updateMember.toString());
	const [asyncDeleteState, resetAsyncDeleteState] = getAsyncUiState(memberActions.deleteMember.toString());

	const { titels, landen, editAllowed } = useSelector((store: StoreState) => ({
		titels: store.data.titels,
		landen: store.data.landen,
		editAllowed: store.userData.profile.hasAnyRole(["administrator", "ledenbeheer beheren"])
	}));

	useEffect(
		() => {
			if (isEmpty(titels)) {
				dispatch(dataActions.loadTitels());
			}

			if (isEmpty(landen)) {
				dispatch(dataActions.loadLanden());
			}
		},
		[false]
	);

	useEffect(
		() => {
			if (asyncUpdateState.isRunning) {
				notification.info({ key: "update", message: "Even geduld", description: "De gegevens worden opgeslaan" });
			}

			if (asyncUpdateState.hasCompleted) {
				if (asyncUpdateState.error) {
					notification.error({ key: "update", message: "Oeps...", description: "Er loopt iets fout (" + asyncUpdateState.error.toString() + ")" });
				} else {
					notification.success({ key: "update", message: "OK", description: "De gegevens werden opgeslaan" });
				}

				resetAsyncUpdateState();
			}
		},
		[asyncUpdateState]
	);

	useEffect(
		() => {
			if (asyncDeleteState.isRunning) {
				notification.info({ key: "delete", message: "Even geduld", description: "De gegevens worden verwijderd." });
			}

			if (asyncDeleteState.hasCompleted) {
				if (asyncDeleteState.error) {
					notification.error({ key: "delete", message: "Oeps...", description: "Er loopt iets fout (" + asyncDeleteState.error.toString() + ")" })
				} else {
					notification.success({ key: "delete", message: "OK", description: "De gegevens werden verwijderd." });
					props.goToMemberList();
				}

				resetAsyncDeleteState();
			}
		},
		[asyncDeleteState]
	);

	function onFinish(values: any) {
		dispatch(memberActions.updateMember({ mid: props.member.id, values }));
	}

	function onDelete() {
		dispatch(memberActions.deleteMember(props.member.id));
	}

	let actions: React.ReactNode = undefined;
	if (editAllowed) {
		actions =
			(<div>
				<Button type="primary" htmlType="submit" disabled={asyncDeleteState.isRunning} loading={asyncUpdateState.isRunning} style={{ marginRight: "16px" }}>Opslaan</Button>
				<Popconfirm
					icon={<QuestionCircleOutlined color="red" />}
					title="Deze persoon, includief lidmaatschap- en fokkerijgegevens, verwijderen?"
					okText="Ja" cancelText="Nee"
					onConfirm={onDelete}>
					<Button type="danger" disabled={asyncUpdateState.isRunning} loading={asyncDeleteState.isRunning}>Gegevens definitief verwijderen</Button>
				</Popconfirm>
			</div>);
	}

	return (
		<Fragment>
			<Form form={form} layout="vertical" onFinish={onFinish} initialValues={props.member}>
				<Row gutter={16}>
					<Col span={4}>
						<Form.Item label="Titel" name="titel" rules={[{ required: true, message: "Gelieve uw titel te kiezen" }]}>
							<Select>
								{titels.map(titel => <Select.Option key={titel} value={titel}>{titel}</Select.Option>)}
							</Select>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Voornaam" name="voornaam" rules={[{ required: true, message: "Gelieve uw voornam in te vullen", whitespace: true, max: 64 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Naam" name="naam" rules={[{ required: true, message: "Gelieve uw naam in te vullen", whitespace: true, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Organisatie" name="organisatie" rules={[{ required: false, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="BTW-nummer" name="btwnummer" rules={[{ required: false, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item label="Adres" name="adres1" rules={[
							{ required: true, message: "Gelieve uw adres in te vullen", whitespace: true, max: 128 },
							{ pattern: /[0-9]+/, message: "Gelieve uw huisnummer in te vullen" }
						]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Postcode" name="postcode" rules={[{ required: true, message: "Gelieve uw postcode in te vullen", whitespace: true, max: 10 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Gemeente" name="gemeente" rules={[{ required: true, message: "Gelieve uw gemeente in te vullen", whitespace: true, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Land" name="land" rules={[{ required: true, message: "Gelieve uw land in te vullen" }]}>
							<Select>
								{landen.map(land => <Select.Option key={land} value={land}>{land}</Select.Option>)}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Row gutter={16}>
							<Col span={16}>
								<Form.Item label="E-mail" name="email" rules={[
									{ type: "email", message: "Gelieve een geldig emailadres in te vullen" },
									{ required: false, message: "Gelieve uw emailadres in te vullen", max: 128 }
								]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label="Telefoon" name="telefoon" rules={[{ required: false, max: 64 }]}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Gsm-nummer" name="gsm" rules={[{ required: false, max: 15 }]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
							<Form.Item label="SLE mag mijn contactgegevens doorgeven aan mensen die rasdieren zoeken" name="akkoord_adres_doorgeven" rules={[{ required: false}]}>
									<Select showSearch={true} onSearch={(e) => console.log(e)}>
										<Select.Option key="1" value="1">Ja</Select.Option>
										<Select.Option key="0" value="0">Nee</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label="Hoe SLE leren kennen?" name="lerenkennen" rules={[{ required: false, max: 256 }]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={8}>
						<Form.Item label="Nota" name="nota"><Input.TextArea rows={8} /></Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col>
						<Form.Item>
							{actions}
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Fragment>
	);
};
