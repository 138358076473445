
import axios from "axios";
import { config } from "../config";

export async function loadLanden(accessToken: string) {
    const response = await axios.get(config.apiUri + "/data/landen", { headers: { authorization: "Bearer " + accessToken } });
    return response.data;
}

export async function loadTitels(accessToken: string) {
    const response = await axios.get(config.apiUri + "/data/titels", { headers: { authorization: "Bearer " + accessToken } });
    return response.data;
}
