
import { Layout, Menu } from "antd";
import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import { Gegevens } from "./ContactGegevens";
import { Fokker } from "./Fokker";
import { LidgeldBetalen } from "./LidgeldBetalen";
import { LidgeldBetalenStatus } from "./LidgeldBetalenStatus";
import { Lidmaatschap } from "./Lidmaatschap";
import { Overzicht } from "./Overzicht";
import { VereenvoudigdBeheer } from "./vereenvoudigd-beheer/Overview";

interface MyComponentProps {
    basePath: string;
}

export const My = (props: MyComponentProps) => {

    return (
        <Layout>
            <Layout.Sider width={200} style={{ background: "#fff" }}>
                <Menu>
                    <Menu.Item key="overzicht"><NavLink to={props.basePath}>Overzicht</NavLink></Menu.Item>
                    <Menu.Item key="contact"><NavLink to={props.basePath + "/contact"}>Contactgegevens</NavLink></Menu.Item>
                    <Menu.Item key="lidmaatschap"><NavLink to={props.basePath + "/lidmaatschap"}>Lidmaatschap</NavLink></Menu.Item>
                    <Menu.Item key="vereenvoudigd-beheer"><NavLink to={props.basePath + "/vereenvoudigd-beheer"}>Vereenvoudigd beheer</NavLink></Menu.Item>
                    <Menu.Item key="fokker"><NavLink to={props.basePath + "/fokker"}>Fokkerij</NavLink></Menu.Item>
                </Menu>
            </Layout.Sider>
            <Layout style={{ padding: "24px 24px 0 24px" }}>
                <Layout.Content style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}>
                    <Switch>
                        <Route path={props.basePath} exact render={() => <Overzicht basePath={props.basePath} />} />
                        <Route path={props.basePath + "/contact"} render={() => <Gegevens />} />
                        <Route path={props.basePath + "/lidmaatschap"} render={() => <Lidmaatschap basePath={props.basePath} />} />
                        <Route path={props.basePath + "/vereenvoudigd-beheer"} render={() => <VereenvoudigdBeheer basePath={props.basePath} />} />
                        <Route path={props.basePath + "/fokker"} render={() => <Fokker />} />
                        <Route path={props.basePath + "/lidgeld-betalen"} exact render={() => <LidgeldBetalen />} />
                        <Route path={props.basePath + "/lidgeld-betalen/status"} render={() => <LidgeldBetalenStatus />} />
                    </Switch>
                </Layout.Content>
            </Layout>
        </Layout>
    );

};
