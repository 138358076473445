import { Button, Col, Form, Input, Row, Select, notification } from "antd";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { dataActions } from "../../store/data";
import { memberActions } from "../../store/member";
import { getAsyncUiState } from "../../store/ui";
import { useHistory } from "react-router";

export const CreateMember = () => {

	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [asyncCreateMemberState, resetAsyncCreateMemberState] = getAsyncUiState(memberActions.createMember.toString());
	const history = useHistory();

	const { titels, landen } = useSelector((store: StoreState) => ({
		titels: store.data.titels,
		landen: store.data.landen
	}));

	useEffect(
		() => {
			if (isEmpty(titels)) {
				dispatch(dataActions.loadTitels());
			}
		},
		[titels]
	);

	useEffect(
		() => {
			if (isEmpty(landen)) {
				dispatch(dataActions.loadLanden());
			}
		},
		[landen]
	);

	useEffect(
		() => {
			if (asyncCreateMemberState.isRunning) {
				notification.info({ key: "create", message: "Even geduld", description: "De gegevens worden verwerkt" });
			}

			if (asyncCreateMemberState.hasCompleted) {
				if (asyncCreateMemberState.error) {
					notification.error({ key: "create", message: "Oeps...", description: "Foutje: " + asyncCreateMemberState.error.toString() });
				} else {
					notification.close("create");
					history.push("/ledenbeheer/" + asyncCreateMemberState.payload.id);
				}

				resetAsyncCreateMemberState();
			}
		},
		[asyncCreateMemberState]
	);

	function onFinish(values: any) {
		dispatch(memberActions.createMember(values));
	}

	const actions = (
		<Fragment>
			<Button type="primary" htmlType="submit">Opslaan</Button>
		</Fragment>
	);

	return (
		<Fragment>
			<h2>Ledenbeheer</h2>
			<Form form={form} layout="vertical" onFinish={onFinish}>
				<Row gutter={16}>
					<Col span={4}>
						<Form.Item label="Titel" name="titel" rules={[{ required: true, message: "Gelieve uw titel te kiezen" }]}>
							<Select>
								{titels.map(titel => <Select.Option key={titel} value={titel}>{titel}</Select.Option>)}
							</Select>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Voornaam" name="voornaam" rules={[{ required: true, message: "Gelieve uw voornam in te vullen", whitespace: true, max: 64 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Naam" name="naam" rules={[{ required: true, message: "Gelieve uw naam in te vullen", whitespace: true, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Organisatie" name="organisatie" rules={[{ required: false, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="BTW-nummer" name="btwnummer" rules={[{ required: false, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item label="Adres" name="adres1" rules={[
							{ required: true, message: "Gelieve uw adres in te vullen", whitespace: true, max: 128 },
							{ pattern: /[0-9]+/, message: "Gelieve uw huisnummer in te vullen" }
						]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Postcode" name="postcode" rules={[{ required: true, message: "Gelieve uw postcode in te vullen", whitespace: true, max: 10 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Gemeente" name="gemeente" rules={[{ required: true, message: "Gelieve uw gemeente in te vullen", whitespace: true, max: 128 }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Land" name="land" rules={[{ required: true, message: "Gelieve uw land in te vullen" }]}>
							<Select>
								{landen.map(land => <Select.Option key={land} value={land}>{land}</Select.Option>)}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Row gutter={16}>
							<Col span={16}>
								<Form.Item label="E-mail" name="email" rules={[
									{ type: "email", message: "Gelieve een geldig emailadres in te vullen" },
									{ required: false, message: "Gelieve uw emailadres in te vullen", max: 128 }
								]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label="Telefoon" name="telefoon" rules={[{ required: false, max: 64 }]}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Gsm-nummer" name="gsm" rules={[{ required: false, max: 15 }]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label="SLE mag mijn contactgegevens doorgeven aan mensen die rasdieren zoeken" name="akkoord_adres_doorgeven" rules={[{ required: false }]}>
									<Select showSearch={true} onSearch={(e) => console.log(e)}>
										<Select.Option key="1" value="1">Ja</Select.Option>
										<Select.Option key="0" value="0">Nee</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label="Hoe SLE leren kennen?" name="lerenkennen" rules={[{ required: false, max: 256 }]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={8}>
						<Form.Item label="Nota" name="nota"><Input.TextArea rows={8} /></Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col>
						<Form.Item>
							{actions}
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Fragment>
	);
}

// class CreateComponent {


// 	onSubmit(e: React.FormEvent<any>) {
// 		e.preventDefault();
// 		this.props.form.validateFieldsAndScroll((err: any, values: any) => {
// 			if (!err) {
// 				this.setState({ status: "saving" });
// 				this.props.create(values)
// 					.then((a) => {
// 						this.props.history.push("/ledenbeheer/" + a.id);
// 					})
// 					.catch(_ => {
// 						this.setState({ status: "error" });
// 					});
// 			}
// 		});
// 	}

// 	render() {

// 		const titels: Array<React.ReactNode> = [];
// 		forEach(this.props.titels, titel => {
// 			titels.push(<Option key={titel} value={titel}>{titel}</Option>);
// 		});

// 		const landen: Array<React.ReactNode> = [];
// 		forEach(this.props.landen, land => {
// 			landen.push(<Option key={land} value={land}>{land}</Option>);
// 		});

// 		let actions: React.ReactNode = null;
// 		if (this.props.createAllowed) {
// 			actions =
// 				(<div>
// 					<Button type="primary" htmlType="submit">Opslaan</Button>
// 				</div>);
// 		}

// 		let statusMessage: React.ReactNode = null;
// 		switch (this.state.status) {
// 			case "error":
// 				statusMessage = <Alert message="Fout bij het opslaan van de wijzigingen" type="error" />;
// 				break;
// 			case "saved":
// 				statusMessage = <Alert message="De wijzigingen werden opgeslaan" type="success" />;
// 				break;
// 			case "saving":
// 				statusMessage = <Alert message="De wijzigingen worden opgeslaan" type="info" />;
// 				break;
// 			default:
// 				statusMessage = null;
// 				break;
// 		}

// 		return (
// 			<div>
// 				<h2>Ledenbeheer</h2>
// 				{statusMessage}
// 				<Form onSubmit={this.onSubmit.bind(this)}>
// 					<Row gutter={16}>
// 						<Col span={4}>
// 							<FormItem label="Titel">
// 								{getFieldDecorator(
// 									"titel",
// 									{
// 										rules: [{ required: true, message: "Gelieve uw titel te kiezen" }]
// 									}
// 								)(
// 									<Select>
// 										{titels}
// 									</Select>
// 								)}
// 							</FormItem>
// 						</Col>
// 						<Col span={4}>
// 							<FormItem label="Voornaam">
// 								{getFieldDecorator(
// 									"voornaam",
// 									{
// 										rules: [{ required: false, whitespace: true, max: 64 }]
// 									}
// 								)(<Input />)}
// 							</FormItem>
// 						</Col>
// 						<Col span={4}>
// 							<FormItem label="Naam">
// 								{getFieldDecorator(
// 									"naam",
// 									{
// 										rules: [{ required: true, message: "Gelieve uw naam in te vullen", whitespace: true, max: 128 }]
// 									}
// 								)(<Input />)}
// 							</FormItem>
// 						</Col>
// 						<Col span={4}>
// 							<FormItem label="Organisatie">
// 								{getFieldDecorator(
// 									"organisatie",
// 									{
// 										rules: [{ required: false, max: 128 }]
// 									}
// 								)(<Input />)}
// 							</FormItem>
// 						</Col>
// 						<Col span={4}>
// 							<FormItem label="BTW-nummer">
// 								{getFieldDecorator(
// 									"btwnummer",
// 									{
// 										rules: [{ required: false, max: 128 }]
// 									}
// 								)(<Input />)}
// 							</FormItem>
// 						</Col>
// 					</Row>
// 					<Row gutter={16}>
// 						<Col span={8}>
// 							<FormItem label="Straat en huisnummer">
// 								{getFieldDecorator(
// 									"adres1",
// 									{
// 										rules: [
// 											{ required: true, message: "Gelieve uw adres in te vullen", max: 128 },
// 											{ pattern: /[0-9]+/, message: "Gelieve uw huisnummer in te vullen" }
// 										]
// 									}
// 								)(<Input />)}
// 							</FormItem>
// 						</Col>
// 						<Col span={4}>
// 							<FormItem label="Postcode">
// 								{getFieldDecorator(
// 									"postcode",
// 									{
// 										rules: [{ required: true, message: "Gelieve uw postcode in te vullen", max: 10 }]
// 									}
// 								)(<Input />)}
// 							</FormItem>
// 						</Col>
// 						<Col span={4}>
// 							<FormItem label="Gemeente">
// 								{getFieldDecorator(
// 									"gemeente",
// 									{
// 										rules: [{ required: true, message: "Gelieve uw gemeente in te vullen", max: 128 }]
// 									}
// 								)(<Input />)}
// 							</FormItem>
// 						</Col>
// 						<Col span={4}>
// 							<FormItem label="Land">
// 								{getFieldDecorator(
// 									"land",
// 									{
// 										rules: [{ required: true, message: "Gelieve uw land in te vullen" }]
// 									}
// 								)(<Select>
// 									{landen}
// 								</Select>)}
// 							</FormItem>
// 						</Col>
// 					</Row>
// 					<Row gutter={16}>
// 						<Col span={12}>
// 							<Row gutter={16}>
// 								<Col span={16}>
// 									<FormItem label="E-mail">
// 										{getFieldDecorator(
// 											"email",
// 											{
// 												rules: [
// 													{ type: "email", message: "Gelieve een geldig emailadres in te vullen" },
// 													{ required: false, message: "Gelieve uw emailadres in te vullen", max: 128 }
// 												]
// 											}
// 										)(<Input />)}
// 									</FormItem>
// 								</Col>
// 							</Row>
// 							<Row gutter={16}>
// 								<Col span={12}>
// 									<FormItem label="Telefoon">
// 										{getFieldDecorator(
// 											"telefoon",
// 											{
// 												rules: [{ required: false, max: 64 }]
// 											}
// 										)(<Input />)}
// 									</FormItem>
// 								</Col>
// 								<Col span={12}>
// 									<FormItem label="Gsm-nummer">
// 										{getFieldDecorator(
// 											"gsm",
// 											{
// 												rules: [{ required: false, max: 15 }]
// 											}
// 										)(<Input />)}
// 									</FormItem>
// 								</Col>
// 							</Row>
// 							<Row gutter={16}>
// 								<Col span={24}>
// 									<FormItem label="Hoe SLE leren kennen?">
// 										{getFieldDecorator(
// 											"lerenkennen",
// 											{
// 												rules: [{ required: false, max: 256 }]
// 											}
// 										)(<Input />)}
// 									</FormItem>
// 								</Col>
// 							</Row>
// 						</Col>
// 						<Col span={8}>
// 							<FormItem label="Nota">
// 								{getFieldDecorator(
// 									"nota",
// 									{
// 									}
// 								)(<TextArea rows={8} />)}
// 							</FormItem>
// 						</Col>
// 					</Row>
// 					<Row gutter={16}>
// 						<Col>
// 							<FormItem>
// 								{actions}
// 							</FormItem>
// 						</Col>
// 					</Row>
// 				</Form>
// 			</div>
// 		);
// 	}
// }

// const mapStateToProps = (state: StoreState) => {
// 	return {
// 		createAllowed: state.userData.profile.hasAnyRole(["ledenbeheer beheren"]),
// 		landen: state.data.landen,
// 		titels: state.data.titels
// 	}
// };

// const mapDispatchToProps = (dispatch: Dispatch) => {
// 	return {
// 		create: (values: any): Promise<any> => {
// 			return dispatch(memberActions.create(values));
// 		},
// 		loadLanden: () => {
// 			return dispatch(dataActions.loadLanden());
// 		},
// 		loadTitels: () => {
// 			return dispatch(dataActions.loadTitels());
// 		}
// 	}
// }

// export const Create = connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreateComponent));